import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import TimeBetweenDate from "./TimeBetweenDate";

const TimeDurationCalc = () => {
  const [hour1, setHour1] = useState(8);
  const [hour2, setHour2] = useState(9);
  const [minute1, setMinute1] = useState(30);
  const [minute2, setMinute2] = useState(30);
  const [second1, setSecond1] = useState(0);
  const [second2, setSecond2] = useState(0);
  const [amOrPm1, setAmOrPm1] = useState("AM");
  const [amOrPm2, setAmOrPm2] = useState("PM");
  const [time, setTime] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  useEffect(() => {
    const startTime = moment(
      `${hour1}:${minute1}:${second1} ${amOrPm1}`,
      "h:mm:ss A"
    );
    const endTime = moment(
      `${hour2}:${minute2}:${second2} ${amOrPm2}`,
      "h:mm:ss A"
    );

    if (endTime.isBefore(startTime)) {
      setTime("End time cannot be earlier than start time.");
      setTotalHours(0);
      setTotalMinutes(0);
      setTotalSeconds(0);
      return;
    }

    const duration = moment.duration(endTime.diff(startTime));

    const totalHours =
      duration.hours() + duration.minutes() / 60 + duration.seconds() / 3600;
    const totalMinutes = duration.asMinutes();
    const totalSeconds = duration.asSeconds();

    setTotalHours(totalHours.toFixed(3));
    setTotalMinutes(Math.round(totalMinutes));
    setTotalSeconds(Math.round(totalSeconds));

    const formattedTime = `${duration.hours()} hours, ${duration.minutes()} minutes, and ${duration.seconds()} seconds`;
    setTime(formattedTime);
  }, [hour1, minute1, second1, amOrPm1, hour2, minute2, second2, amOrPm2]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.9} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                1. Time Duration Between Two Times
              </Typography>
              <Typography className="common-sub-heading-desc">
                Start Time:
              </Typography>
              <InputComponent
                isInput={true}
                label="Hour"
                max={12}
                value={hour1}
                setState={setHour1}
              />
              <InputComponent
                isInput={true}
                label="Minute"
                max={59}
                value={minute1}
                setState={setMinute1}
              />
              <InputComponent
                isInput={true}
                label="Second"
                max={59}
                value={second1}
                setState={setSecond1}
              />
              <SelectBoxComponent
                value={amOrPm1}
                setValue={setAmOrPm1}
                data={["AM", "PM"]}
                placeholder="Select AM or PM"
              />
              <Typography className="common-sub-heading-desc">
                End Time:
              </Typography>
              <InputComponent
                isInput={true}
                label="Hour"
                max={12}
                value={hour2}
                setState={setHour2}
              />
              <InputComponent
                isInput={true}
                max={59}
                label="Minute"
                value={minute2}
                setState={setMinute2}
              />
              <InputComponent
                isInput={true}
                max={59}
                label="Second"
                value={second2}
                setState={setSecond2}
              />
              <SelectBoxComponent
                value={amOrPm2}
                setValue={setAmOrPm2}
                data={["AM", "PM"]}
                placeholder="Select AM or PM"
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                isInput={false}
                label="Total Time"
                value={time}
                date={true}
              />
              <Grid className="result-common-div">
                <InputComponent
                  label="Hours"
                  isInput={false}
                  value={totalHours}
                  date={true}
                />
                <InputComponent
                  label="Minutes"
                  isInput={false}
                  value={totalMinutes}
                  date={true}
                />
                <InputComponent
                  label="Seconds"
                  isInput={false}
                  value={totalSeconds}
                  date={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <TimeBetweenDate />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
};

export default TimeDurationCalc;
