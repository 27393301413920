import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import Table from "./Table";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import moment from "moment";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import CheckboxGroup from "../../Common/Checkbox/Checkbox";
import TableComponent from "../../Common/TableComponent/TableComponent";

const TimeCardOptionData = [
  {
    label: "Include payment information",
    value: "Include payment information",
  },
];

export default function TimeCardCalc() {
  const [date, setDate] = useState({
    dt0: "Monday",
    dt1: "Tuesday",
    dt2: "Wednesday",
    dt3: "Thursday",
    dt4: "Friday",
    dt5: "Saturday",
    dt6: "Sunday",
  });
  const [from, setFrom] = useState({
    fr0: "8:30AM",
    fr1: "8:30AM",
    fr2: "8:30AM",
    fr3: "8:30AM",
    fr4: "8:30AM",
    fr5: "8:30AM",
    fr6: "8:30AM",
  });
  const [to, setTo] = useState({
    to0: "5:00PM",
    to1: "5:00PM",
    to2: "5:00PM",
    to3: "5:00PM",
    to4: "5:00PM",
    to5: "5:00PM",
    to6: "5:00PM",
  });
  const [breaks, setBreaks] = useState({
    br0: "0:30",
    br1: "0:30",
    br2: "0:30",
    br3: "0:30",
    br4: "0:30",
    br5: "0:30",
    br6: "0:30",
  });
  const [timeCardCheckBoxOption, setTimeCardCheckBoxOption] = useState(false);
  const [basePayRate, setBasePayRate] = useState(35);
  const [overPayPerDay, setOverPayPerDay] = useState(8);
  const [overPayPerWeek, setOverPayPerWeek] = useState(40);
  const [overPayType, setOverPayType] = useState({
    label: `Overtime pay after ${overPayPerWeek} hours per week`,
    value: "overtime per week",
  });
  const [overtimePayRate, setOvertimePayRate] = useState(1.5);

  const [timeCardTableData, setTimeCardTableData] = useState([]);
  const [totalHoursResult, setTotalHoursResult] = useState(0);
  const [basePay, setBasePay] = useState(0);
  const [overTimePay, setOverTimePay] = useState(0);
  const [totalPay, setTotalPay] = useState(0);

  useEffect(() => {
    const calculateDaySummary = (start, end, breakTime) => {
      const startTime = moment(start, "hh:mma");
      const endTime = moment(end, "hh:mma");
      const breakHours = moment.duration(breakTime);

      let totalHours = moment.duration(endTime.diff(startTime)).asHours();
      totalHours = Math.max(totalHours - breakHours.asHours(), 0);

      const baseHours =
        overPayType?.value === "no overtime"
          ? totalHours
          : Math.min(totalHours, overPayPerDay);
      const overtimeHours =
        totalHours > overPayPerDay ? totalHours - overPayPerDay : 0;

      const hours = Math.floor(totalHours);
      const minutes = Math.round((totalHours - hours) * 60);

      return {
        totalHours: totalHours,
        hours: hours,
        minutes: minutes,
        basePay: baseHours * basePayRate,
        overtimePay: overtimeHours * basePayRate * overtimePayRate,
        totalPay:
          baseHours * basePayRate +
          overtimeHours * basePayRate * overtimePayRate,
      };
    };

    let totalHoursAccumulator = 0;
    let totalBasePayAccumulator = 0;
    let totalOverTimePayAccumulator = 0;

    const rows = Object.keys(date).map((key, index) => {
      const daySummary = calculateDaySummary(
        from[`fr${index}`],
        to[`to${index}`],
        breaks[`br${index}`]
      );

      totalHoursAccumulator += +daySummary?.totalHours;

      totalBasePayAccumulator += +daySummary?.basePay;
      if (overPayType?.value === "overtime per day") {
        totalOverTimePayAccumulator += +daySummary?.overtimePay;
      }

      return {
        date: date[`dt${index}`],
        summary: `${from[`fr${index}`]} - ${to[`to${index}`]} (${
          breaks[`br${index}`]
        } break)`,
        hours:
          daySummary.minutes > 0
            ? `${daySummary.hours}h ${daySummary.minutes}m`
            : `${daySummary.hours}h`,
        ...((overPayType?.value === "no overtime" ||
          overPayType?.value === "no overtime per day" ||
          overPayType?.value === "overtime per day") && {
          basePay: (daySummary?.basePay).toFixed(2),
        }),
        ...(overPayType?.value === "overtime per day" && {
          overtimePay: (daySummary?.overtimePay).toFixed(2),
        }),
        ...(overPayType?.value === "overtime per day" && {
          total: (daySummary?.totalPay).toFixed(2),
        }),
      };
    });

    setTimeCardTableData(rows);
    setTotalHoursResult(
      Math.round((totalHoursAccumulator % 1) * 60) > 0
        ? `${Math.floor(+totalHoursAccumulator)}h ${Math.round(
            (+totalHoursAccumulator % 1) * 60
          )}m`
        : `${Math.floor(+totalHoursAccumulator)}h`
    );

    let totalBasePay = 0;
    let totalOvertimePay = 0;

    if (overPayType?.value === "no overtime per week") {
      totalBasePay = +overPayPerWeek * +basePayRate;
    } else if (overPayType?.value === "overtime per week") {
      totalBasePay = +overPayPerWeek * +basePayRate;
      totalOvertimePay =
        (+totalHoursAccumulator - +overPayPerWeek) *
        basePayRate *
        overtimePayRate;
    } else if (overPayType?.value === "overtime per day") {
      totalBasePay = +totalBasePayAccumulator;
      totalOvertimePay = +totalOverTimePayAccumulator;
    } else {
      totalBasePay = +totalBasePayAccumulator;
    }

    setBasePay(+totalBasePay.toFixed(2));
    setOverTimePay(+totalOvertimePay.toFixed(2));
    setTotalPay((+totalBasePay + +totalOvertimePay).toFixed(2));
  }, [
    date,
    from,
    to,
    breaks,
    basePayRate,
    overPayPerDay,
    overPayPerWeek,
    overPayType,
    overtimePayRate,
  ]);

  const TimeCardCalcRadio = [
    {
      label: "No overtime rate, pay base rate for overtime worked",
      value: "no overtime",
    },
    {
      label: `No overtime pay, pay max ${overPayPerDay} hours per day`,
      value: "no overtime per day",
    },
    {
      label: `No overtime pay, pay max ${overPayPerWeek} hours per week`,
      value: "no overtime per week",
    },
    {
      label: `Overtime pay after ${overPayPerDay} hours per day`,
      value: "overtime per day",
    },
    {
      label: `Overtime pay after ${overPayPerWeek} hours per week`,
      value: "overtime per week",
    },
  ];

  const handleCheckboxChange = (newSelectedValues) => {
    if (newSelectedValues[0] === "Include payment information") {
      setTimeCardCheckBoxOption(true);
    } else {
      setTimeCardCheckBoxOption(false);
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8.5}>
            <Grid item className="calculation-part ">
              <Grid item>
                <Table
                  numOfRows={Object.keys(date).length}
                  headerData={["Date", "From", "To", "Break Deduction"]}
                  date={{ data: date, setter: setDate }}
                  from={{ data: from, setter: setFrom }}
                  to={{ data: to, setter: setTo }}
                  breaks={{ data: breaks, setter: setBreaks }}
                />
                <CheckboxGroup
                  label="Select Options"
                  data={TimeCardOptionData}
                  valueSetter={(e) => handleCheckboxChange(e)}
                  row={true}
                  type="default"
                />
                {timeCardCheckBoxOption && (
                  <>
                    <InputComponent
                      isInput={true}
                      label="Base Pay Rate(per hour)"
                      flag="dollar"
                      min={0}
                      max={1000}
                      setState={setBasePayRate}
                      value={basePayRate}
                    />
                    <InputComponent
                      isInput={true}
                      label="Over pay(per day)"
                      min={0}
                      max={10}
                      setState={setOverPayPerDay}
                      value={overPayPerDay}
                    />
                    <InputComponent
                      isInput={true}
                      label="Over pay(per week)"
                      min={0}
                      max={50}
                      setState={setOverPayPerWeek}
                      value={overPayPerWeek}
                    />
                    <RadioButtonComponent
                      label={"Overtime Pay:"}
                      data={TimeCardCalcRadio}
                      defaultValue={"overtime per week"}
                      valueSetter={setOverPayType}
                      row={true}
                    />
                    {(overPayType?.value === "overtime per week" ||
                      overPayType?.value === "overtime per day") && (
                      <InputComponent
                        isInput={true}
                        label="Overtime Rate (e.g., 1.5x Base Rate)"
                        min={0}
                        max={5}
                        setState={setOvertimePayRate}
                        value={overtimePayRate}
                      />
                    )}
                  </>
                )}
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>{" "}
              <Grid className="result-common-div">
                <Grid sx={{ p: 2 }}>
                  <TableComponent
                    rows={timeCardTableData}
                    headerData={[
                      "Date",
                      "Summary",
                      "Hours",
                      ...(overPayType?.value === "no overtime" ||
                      overPayType?.value === "no overtime per day" ||
                      overPayType?.value === "overtime per day"
                        ? ["Base Pay"]
                        : []),
                      ...(overPayType?.value === "overtime per day"
                        ? ["Overtime Pay"]
                        : []),
                      ...(overPayType?.value === "overtime per day"
                        ? ["Total"]
                        : []),
                    ]}
                    isMinWidth={300}
                    currency={"dollar"}
                  />
                </Grid>
                <InputComponent
                  label="Total Hours"
                  isInput={false}
                  value={totalHoursResult || "0"}
                  date={true}
                />
                {timeCardCheckBoxOption && (
                  <>
                    {(overPayType?.value === "overtime per day" ||
                      overPayType?.value === "overtime per week") && (
                      <InputComponent
                        label="Base Pay"
                        isInput={false}
                        value={basePay || "0"}
                        flag={"dollar"}
                      />
                    )}
                    {overTimePay > 0 && (
                      <InputComponent
                        label="Overtime Pay"
                        isInput={false}
                        value={overTimePay || "0"}
                        flag={"dollar"}
                      />
                    )}
                    <InputComponent
                      label="Total Pay"
                      isInput={false}
                      value={totalPay || "0"}
                      flag={"dollar"}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
