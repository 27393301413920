import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { BOOLEANS } from "../../../Utils/calorieHelper";

const VaDisabilityMaritalStatus = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
];

function VaDisabilityCalc() {
  const [addDisabilities, setAddDisabilities] = useState("Left Leg");
  const [disabilityRating, setDisabilityRating] = useState("20%");
  const [childrenUnder18, setChildrenUnder18] = useState("0");
  const [childrenInSchool, setChildrenInSchool] = useState("0");
  const [dependentParents, setDependentParents] = useState("0");
  const [maritalStatus, setMaritalStatus] = useState({
    label: "Single",
    value: "single",
  });
  const [spouseNeedAid, setSpouseNeedAid] = useState({
    label: "No",
    value: "no",
  });

  const [currentDisabilitiesApplied, setCurrentDisabilitiesApplied] = useState(
    []
  );
  const [armFactor, setArmFactor] = useState(0);
  const [legFactor, setLegFactor] = useState(0);
  const [totalDisabilityRating, setTotalDisabilityRating] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const rateTable = {
    baseRates: {
      "Veteran Alone": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 537.42,
        "40%": 774.16,
        "50%": 1102.04,
        "60%": 1395.93,
        "70%": 1759.19,
        "80%": 2044.89,
        "90%": 2297.96,
        "100%": 3831.3,
      },
      "With Spouse": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 601.42,
        "40%": 859.16,
        "50%": 1208.04,
        "60%": 1523.93,
        "70%": 1908.19,
        "80%": 2214.89,
        "90%": 2489.96,
        "100%": 4044.91,
      },
      "With Spouse and One Parent": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 652.42,
        "40%": 927.16,
        "50%": 1293.04,
        "60%": 1625.93,
        "70%": 2028.19,
        "80%": 2351.89,
        "90%": 2643.96,
        "100%": 4216.35,
      },
      "With Spouse and Two Parents": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 703.42,
        "40%": 995.16,
        "50%": 1378.04,
        "60%": 1727.93,
        "70%": 2148.19,
        "80%": 2488.89,
        "90%": 2797.96,
        "100%": 4387.79,
      },
      "With One Parent": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 588.42,
        "40%": 842.16,
        "50%": 1187.04,
        "60%": 1497.93,
        "70%": 1879.19,
        "80%": 2181.89,
        "90%": 2451.96,
        "100%": 4002.74,
      },
      "With Two Parents": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 639.42,
        "40%": 910.16,
        "50%": 1272.04,
        "60%": 1599.93,
        "70%": 1999.19,
        "80%": 2318.89,
        "90%": 2605.96,
        "100%": 4174.18,
      },
      "With One Child": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 579.42,
        "40%": 831.16,
        "50%": 1173.04,
        "60%": 1480.93,
        "70%": 1858.19,
        "80%": 2158.89,
        "90%": 2425.96,
        "100%": 3974.15,
      },
      "With Spouse and Child": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 648.42,
        "40%": 922.16,
        "50%": 1287.04,
        "60%": 1617.93,
        "70%": 2018.19,
        "80%": 2340.89,
        "90%": 2630.96,
        "100%": 4201.35,
      },
      "With Child and One Parent": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 630.42,
        "40%": 899.16,
        "50%": 1258.04,
        "60%": 1582.93,
        "70%": 1978.19,
        "80%": 2295.89,
        "90%": 2579.96,
        "100%": 4145.59,
      },
      "With Child and Two Parents": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 681.42,
        "40%": 967.16,
        "50%": 1343.04,
        "60%": 1684.93,
        "70%": 2098.19,
        "80%": 2432.89,
        "90%": 2733.96,
        "100%": 4317.03,
      },
      "With Spouse, Child and One Parent": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 699.42,
        "40%": 990.16,
        "50%": 1372.04,
        "60%": 1719.93,
        "70%": 2138.19,
        "80%": 2477.89,
        "90%": 2784.96,
        "100%": 4372.79,
      },
      "With Spouse, Child and Two Parents": {
        "10%": 175.51,
        "20%": 346.95,
        "30%": 750.42,
        "40%": 1058.16,
        "50%": 1457.04,
        "60%": 1821.93,
        "70%": 2258.19,
        "80%": 2614.89,
        "90%": 2938.96,
        "100%": 4544.23,
      },
    },
    additionalRates: {
      childUnder18: {
        "30%": 31.0,
        "40%": 42.0,
        "50%": 53.0,
        "60%": 63.0,
        "70%": 74.0,
        "80%": 84.0,
        "90%": 95.0,
        "100%": 106.14,
      },
      childInSchool: {
        "30%": 102.0,
        "40%": 137.0,
        "50%": 171.0,
        "60%": 205.0,
        "70%": 239.0,
        "80%": 274.0,
        "90%": 308.0,
        "100%": 342.85,
      },
      spouseAidAttendance: {
        "30%": 58.0,
        "40%": 78.0,
        "50%": 98.0,
        "60%": 117.0,
        "70%": 137.0,
        "80%": 157.0,
        "90%": 176.0,
        "100%": 195.92,
      },
    },
  };

  const combineRatings = (ratings) => {
    if (!ratings.length) return 0;
    if (ratings.length === 1) return ratings[0];

    ratings.sort((a, b) => b - a);
    let combinedRating = ratings[0];

    for (let i = 1; i < ratings.length; i++) {
      const currentRating = ratings[i];
      const remainingEfficiency =
        (100 - combinedRating) * (currentRating / 100);
      combinedRating = combinedRating + remainingEfficiency;
    }

    return Number(combinedRating.toFixed(1));
  };

  const calculateBilateralFactors = (disabilities) => {
    const disabilityGroups = {
      leftArm: [],
      rightArm: [],
      leftLeg: [],
      rightLeg: [],
    };

    disabilities.forEach((disability) => {
      const [ratingStr, ...partWords] = disability.split(" ");
      const rating = parseInt(ratingStr);
      const part = partWords.join(" ");

      switch (part) {
        case "Left Arm":
          disabilityGroups.leftArm.push(rating);
          break;
        case "Right Arm":
          disabilityGroups.rightArm.push(rating);
          break;
        case "Left Leg":
          disabilityGroups.leftLeg.push(rating);
          break;
        case "Right Leg":
          disabilityGroups.rightLeg.push(rating);
          break;
        default:
          break;
      }
    });

    let armBilateralFactor = 0;
    if (
      disabilityGroups.leftArm.length > 0 &&
      disabilityGroups.rightArm.length > 0
    ) {
      const leftArmCombined = combineRatings(disabilityGroups.leftArm);
      const rightArmCombined = combineRatings(disabilityGroups.rightArm);

      const combinedArmRating = combineRatings([
        leftArmCombined,
        rightArmCombined,
      ]);

      armBilateralFactor = Number((combinedArmRating * 0.1).toFixed(1));
    }

    let legBilateralFactor = 0;
    if (
      disabilityGroups.leftLeg.length > 0 &&
      disabilityGroups.rightLeg.length > 0
    ) {
      const leftLegCombined = combineRatings(disabilityGroups.leftLeg);
      const rightLegCombined = combineRatings(disabilityGroups.rightLeg);

      const combinedLegRating = combineRatings([
        leftLegCombined,
        rightLegCombined,
      ]);

      legBilateralFactor = Number((combinedLegRating * 0.1).toFixed(1));
    }
    setLegFactor(legBilateralFactor);
    setArmFactor(armBilateralFactor);

    return {
      armBilateralFactor,
      legBilateralFactor,
      combinedRatings: {
        leftArm: combineRatings(disabilityGroups.leftArm),
        rightArm: combineRatings(disabilityGroups.rightArm),
        leftLeg: combineRatings(disabilityGroups.leftLeg),
        rightLeg: combineRatings(disabilityGroups.rightLeg),
      },
    };
  };

  const calculateCombinedRating = (disabilities) => {
    if (disabilities.length === 0) return 0;

    const { armBilateralFactor, legBilateralFactor } =
      calculateBilateralFactors(disabilities);

    const ratings = disabilities
      .map((disability) => parseInt(disability.split("%")[0]))
      .sort((a, b) => b - a);

    let combinedRating = combineRatings(ratings);

    combinedRating += armBilateralFactor + legBilateralFactor;

    return Math.min(Math.round(combinedRating / 10) * 10, 100);
  };

  useEffect(() => {
    const newTotalRating = calculateCombinedRating(currentDisabilitiesApplied);
    setTotalDisabilityRating(newTotalRating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDisabilitiesApplied]);

  useEffect(() => {
    const getBaseRateCategory = () => {
      const hasSpouse = maritalStatus.value === "married";
      const hasChild =
        parseInt(childrenUnder18) > 0 || parseInt(childrenInSchool) > 0;
      const parentCount = parseInt(dependentParents);

      if (hasSpouse) {
        if (hasChild) {
          if (parentCount === 2) return "With Spouse, Child and Two Parents";
          if (parentCount === 1) return "With Spouse, Child and One Parent";
          return "With Spouse and Child";
        } else {
          if (parentCount === 2) return "With Spouse and Two Parents";
          if (parentCount === 1) return "With Spouse and One Parent";
          return "With Spouse";
        }
      } else {
        if (hasChild) {
          if (parentCount === 2) return "With Child and Two Parents";
          if (parentCount === 1) return "With Child and One Parent";
          return "With One Child";
        } else {
          if (parentCount === 2) return "With Two Parents";
          if (parentCount === 1) return "With One Parent";
          return "Veteran Alone";
        }
      }
    };

    const calculateMonthlyPayment = () => {
      if (totalDisabilityRating < 30) {
        return (
          rateTable.baseRates["Veteran Alone"][`${totalDisabilityRating}%`] || 0
        );
      }

      const rating = `${totalDisabilityRating}%`;
      const category = getBaseRateCategory();
      let payment = rateTable.baseRates[category][rating] || 0;

      const additionalChildren = Math.max(0, parseInt(childrenUnder18) - 1);
      if (additionalChildren > 0) {
        payment +=
          additionalChildren *
          (rateTable.additionalRates.childUnder18[rating] || 0);
      }

      const schoolChildren = parseInt(childrenInSchool);
      if (schoolChildren > 0) {
        payment +=
          schoolChildren *
          (rateTable.additionalRates.childInSchool[rating] || 0);
      }

      if (maritalStatus.value === "married" && spouseNeedAid.value === "yes") {
        payment += rateTable.additionalRates.spouseAidAttendance[rating] || 0;
      }

      return Math.round(payment * 100) / 100;
    };
    const newMonthlyPayment = calculateMonthlyPayment();
    setMonthlyPayment(newMonthlyPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalDisabilityRating,
    maritalStatus,
    childrenUnder18,
    childrenInSchool,
    dependentParents,
    spouseNeedAid,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <Typography className="common-sub-heading-desc">
                Add Disabilities:
              </Typography>
              <SelectBoxComponent
                label={"Add Disabilities"}
                value={addDisabilities}
                setValue={setAddDisabilities}
                data={["Left Leg", "Right Leg", "Left Arm", "Right Arm"]}
                placeholder="Select Disabilities"
              />

              <SelectBoxComponent
                label={"Select Disability Rating"}
                value={disabilityRating}
                setValue={setDisabilityRating}
                data={[
                  "10%",
                  "20%",
                  "30%",
                  "40%",
                  "50%",
                  "60%",
                  "70%",
                  "80%",
                  "90%",
                  "100%",
                ]}
                placeholder="Select Disability Rating"
              />
              <Grid sx={{ textAlign: "center", marginTop: "12px" }}>
                <Button
                  onClick={() => {
                    if (addDisabilities && disabilityRating) {
                      const newEntry = `${disabilityRating} ${addDisabilities}`;
                      if (!currentDisabilitiesApplied.includes(newEntry)) {
                        setCurrentDisabilitiesApplied((prev) => [
                          ...prev,
                          newEntry,
                        ]);
                      }
                      setAddDisabilities("");
                      setDisabilityRating("");
                    }
                  }}
                  variant="contained"
                >
                  Add Disability
                </Button>
              </Grid>

              <Typography className="common-sub-heading-desc">
                Additional Payment Amount Factors:
              </Typography>
              <SelectBoxComponent
                label={"Dependent Children Under 18"}
                value={childrenUnder18}
                setValue={setChildrenUnder18}
                data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                placeholder="Select a Number"
              />
              <SelectBoxComponent
                label={"Dependent Children in School (age 18-24)"}
                value={childrenInSchool}
                setValue={setChildrenInSchool}
                data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                placeholder="Select a Number"
              />
              <SelectBoxComponent
                label={"Dependent Parents"}
                value={dependentParents}
                setValue={setDependentParents}
                data={["0", "1", "2"]}
                placeholder="Select a Number"
              />
              <RadioButtonComponent
                label={"Marital Status"}
                data={VaDisabilityMaritalStatus}
                defaultValue={"single"}
                valueSetter={setMaritalStatus}
                row={false}
              />
              {maritalStatus?.value === "married" && (
                <RadioButtonComponent
                  label={"Does your spouse need Aid and Attendance (A/A)?"}
                  data={BOOLEANS}
                  defaultValue={"no"}
                  valueSetter={setSpouseNeedAid}
                  row={false}
                />
              )}
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Current Disabilities Applied"
                isInput={false}
                value={currentDisabilitiesApplied.join(", ")}
                date={true}
              />
              <InputComponent
                label="Bilateral Arm Factor Applied"
                isInput={false}
                value={armFactor}
                date={true}
              />
              <InputComponent
                label="Bilateral Leg Factor Applied"
                isInput={false}
                value={legFactor}
                date={true}
              />
              <InputComponent
                label="Current Disability Rating"
                isInput={false}
                value={totalDisabilityRating}
                flag="percentage"
              />
              <InputComponent
                label={"Your Monthly Payment Amount"}
                isInput={false}
                value={monthlyPayment}
                flag={"dollar"}
              />
            </Grid>
          </Grid>
        </Grid>

        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default VaDisabilityCalc;
