import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";

import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";
import dayjs from "dayjs";
import { BondConversion } from "../../../Utils/calorieHelper";

function BondCalc() {
  const [price, setPrice] = useState(0);
  const [faceValue, setFaceValue] = useState(100);
  const [yieldTax, setYieldTax] = useState(6);
  const [maturity, setMaturity] = useState(3);
  const [annualCoupons, setAnnualCoupons] = useState(5);
  const [compound, setCompound] = useState("annually");

  const [faceValue2, setFaceValue2] = useState(100);
  const [yieldTax2, setYieldTax2] = useState(6);
  const [annualCoupons2, setAnnualCoupons2] = useState(5);
  const [compound2, setCompound2] = useState("annually");
  const [maturityDate, setMaturityDate] = useState(dayjs(new Date()));
  const [settlementDate, setSettlementDate] = useState(dayjs(new Date()));

  const [dirtyPrice, setDirtyPrice] = useState(0);
  const [cleanPrice, setCleanPrice] = useState(0);
  const [accruedInterest, setAccruedInterest] = useState(0);
  const [interestAccruedDays, setInterestAccruedDays] = useState(0);
  const [conversion, setConversion] = useState({
    label: "30/360",
    value: "30/360",
  });

  useEffect(() => {
    if (maturity <= 0) return; // Early return if maturity is non-positive

    // Convert yield rate and coupon rate to decimals
    const y = +yieldTax / 100; // Yield to Maturity (YTM)
    

    // Determine the number of periods and periodic yield
    let n, r;
    switch (compound) {
      case "annually":
        n = +maturity;
        r = y;
        break;
      case "semiannually":
        n = +maturity * 2;
        r = y / 2;
        break;
      case "quarterly":
        n = +maturity * 4;
        r = y / 4;
        break;
      case "monthly":
        n = +maturity * 12;
        r = y / 12;
        break;
      default:
        throw new Error("Invalid coupon frequency");
    }

    // Calculate the bond price using the provided formula
    const PMT = (+faceValue * (+annualCoupons / 100)) / (n / +maturity);
    const price =
      (PMT * (1 - Math.pow(1 + r, -n))) / r + faceValue * Math.pow(1 + r, -n);

    setPrice(price.toFixed(2));
  }, [faceValue, yieldTax, maturity, annualCoupons, compound]);

  useEffect(() => {
    function calculateAccruedInterestDays() {
      const startDate = new Date(maturityDate);
      const endDate = new Date(settlementDate);

      if (
        !maturityDate ||
        !settlementDate ||
        startDate.getTime() === endDate.getTime()
      ) {
        return 0;
      }

      let days = 0;

      switch (conversion.value) {
        case "30/360":
          // 30/360 method assumes each month has 30 days and the year has 360 days
          const startDay = Math.min(startDate.getDate(), 30);
          const endDay = Math.min(endDate.getDate(), 30);
          days =
            360 * (endDate.getFullYear() - startDate.getFullYear()) +
            30 * (endDate.getMonth() - startDate.getMonth()) +
            (endDay - startDay);
          break;

        case "Actual/360":
          // Actual days between the two dates
          days = (endDate - startDate) / (1000 * 60 * 60 * 24); // Actual days
          break;

        case "Actual/365":
          // Actual days between the two dates
          days = (endDate - startDate) / (1000 * 60 * 60 * 24); // Actual days
          break;

        case "Actual/Actual":
          // Calculate actual number of days between dates
          const daysBetween = (endDate - startDate) / (1000 * 60 * 60 * 24);
          const daysInStartYear =
            (new Date(startDate.getFullYear(), 11, 31) -
              new Date(startDate.getFullYear(), 0, 0)) /
            (1000 * 60 * 60 * 24);
          const daysInEndYear =
            (new Date(endDate.getFullYear(), 11, 31) -
              new Date(endDate.getFullYear(), 0, 0)) /
            (1000 * 60 * 60 * 24);
          days = daysBetween * (365 / Math.max(daysInStartYear, daysInEndYear));
          break;

        default:
          throw new Error("Invalid day-count convention");
      }

      return days;
    }

    if (!settlementDate || !maturityDate) return;

    const y = +yieldTax2 / 100;
    const c = (+annualCoupons2 / 100) * +faceValue2;

    let n, r;
    const timeDifference = new Date(settlementDate) - new Date(maturityDate);

    switch (compound2) {
      case "annually":
        n = Math.floor(timeDifference / (365 * 24 * 60 * 60 * 1000)) + 1;
        r = y;
        break;
      case "semiannually":
        n = Math.floor(timeDifference / (182.5 * 24 * 60 * 60 * 1000)) + 1;
        r = y / 2;
        break;
      case "quarterly":
        n = Math.floor(timeDifference / (91.25 * 24 * 60 * 60 * 1000)) + 1;
        r = y / 4;
        break;
      case "monthly":
        n = Math.floor(timeDifference / (30.42 * 24 * 60 * 60 * 1000)) + 1;
        r = y / 12;
        break;
      default:
        throw new Error("Invalid coupon frequency");
    }

    const price =
      (c * (1 - Math.pow(1 + r, -n))) / r + faceValue2 * Math.pow(1 + r, -n);
    setDirtyPrice(price.toFixed(4));

    const daysAccrued = calculateAccruedInterestDays();
    setInterestAccruedDays(`${daysAccrued.toFixed(0)} Days`);

    // Calculate accrued interest based on the day-count convention
    let dayCountBasis;
    switch (conversion.value) {
      case "30/360":
        dayCountBasis = 360; // Divide by 360 for 30/360
        break;
      case "Actual/360":
        dayCountBasis = 360; // Divide by 360 for Actual/360
        break;
      case "Actual/365":
        dayCountBasis = 365; // Divide by 365 for Actual/365
        break;
      case "Actual/Actual":
        dayCountBasis = 365; // For Actual/Actual, typically use 365 for accrued interest
        break;
      default:
        throw new Error("Invalid day-count convention");
    }

    const accrued = daysAccrued === 0 ? 0 : (c * daysAccrued) / dayCountBasis;
    setAccruedInterest(accrued.toFixed(4));

    const clean = price - accrued;
    setCleanPrice(clean.toFixed(4));
  }, [
    annualCoupons2,
    compound2,
    conversion.value,
    faceValue2,
    maturityDate,
    settlementDate,
    yieldTax2,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container gap={2.5}>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. Find Bond Price
            </Typography>
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Face value"
                value={faceValue}
                min={0}
                setState={setFaceValue}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Yield"
                setState={setYieldTax}
                value={yieldTax}
                min={0}
                max={999}
              />
              <InputComponent
                isInput={true}
                flag="year"
                label="Time to maturity"
                setState={setMaturity}
                value={maturity}
                min={0}
                max={199}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Annual coupon"
                setState={setAnnualCoupons}
                value={annualCoupons}
                min={0}
                max={999}
              />
              <SelectBoxComponent
                value={compound}
                setValue={setCompound}
                data={["annually", "semiannually", "quarterly", "monthly"]}
                placeholder="Select frequency"
                label="Coupon frequency"
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <Typography sx={{ ml: 1, mt: 3, fontSize: "1.3rem" }}>
                Given the face value, yield, time to maturity, and annual
                coupon,
                <b>
                  the price is: <span style={{ color: "green" }}>{price}</span>
                </b>{" "}
                or higher on the final.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              2. Bond pricing calculator
            </Typography>
            <Grid>
              <InputComponent
                isInput={true}
                flag="dollar"
                label="Face value"
                value={faceValue2}
                min={0}
                setState={setFaceValue2}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Yield"
                setState={setYieldTax2}
                value={yieldTax2}
                min={0}
                max={999}
              />

              <InputComponent
                isInput={true}
                flag="percentage"
                label="Annual coupon"
                setState={setAnnualCoupons2}
                value={annualCoupons2}
                min={0}
                max={999}
              />
              <SelectBoxComponent
                value={compound2}
                setValue={setCompound2}
                data={["annually", "semiannually", "quarterly", "monthly"]}
                placeholder="Select frequency"
                label="Coupon frequency"
              />
              <DatePickerCommon
                label="Maturity date"
                value={maturityDate}
                setValue={setMaturityDate}
                enableFutureDate={true}
              />
              <DatePickerCommon
                label="Settlement date"
                value={settlementDate}
                setValue={setSettlementDate}
                enableFutureDate={true}
              />
              <RadioButtonComponent
                label={"Day-count convention to use:"}
                data={BondConversion}
                defaultValue={"30/360"}
                valueSetter={setConversion}
                row={true}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Dirty price"
                isInput={false}
                value={dirtyPrice}
              />{" "}
              <InputComponent
                label="Clean price"
                isInput={false}
                value={cleanPrice}
              />{" "}
              <InputComponent
                label="Accrued interest"
                isInput={false}
                value={accruedInterest}
              />{" "}
              <InputComponent
                label="Interest accrued days"
                isInput={false}
                value={interestAccruedDays}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default BondCalc;
