import { Grid, Typography } from "@mui/material";
import React from "react";
import "./inputComponent.scss";
import { formatAmountIndian } from "../../../Utils/CommonFunction";

function InputComponent(props) {
  const {
    label,
    flag,
    value,
    isInput = false,
    setState,
    date = false,
    type = "number",
    dis = false,
    min = 0,
    max,
    placeholder,
    color = "#404040",
    isCombo = false,
    otherValue,
    setOtherState,
  } = props;

  const handleChange = (e) => {
    if (type === "number") {
      if (min > e.target.value || max < e.target.value) {
        setState();
      } else {
        setState(e.target.value);
      }
    } else {
      setState(e.target.value);
    }
  };

  const handleBlur = (e) => {
    if (type === "number") {
      if (e.target.value < 0 || !e.target.value) {
        setState(min ? min : 0);
      } else if (e.target.value > max) {
        setState(max);
      } else if (e.target.value < min) {
        setState(min);
      }
    } else {
      setState(e.target.value);
    }
  };

  const handleFocus = (e) => {
    if (e.target.value === "0") {
      setState("");
    }
  };

  return (
    <>
      {isInput && isCombo ? (
        <Grid className="input-div">
          <Typography className="input-label">{label}</Typography>
          <Grid
            className="common-input-parent"
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="number"
              className={
                flag === "rupee" || flag === "dollar"
                  ? "class-rupee-dollar"
                  : flag === "day" || flag === "month"
                  ? "class-except-month-day"
                  : "class-except-rupee-dollar"
              }
              style={{
                cursor: dis ? "not-allowed" : "auto",
              }}
              id={label}
              name={label}
              onChange={handleChange}
              value={value}
              defaultValue={value}
              min={min}
              max={max}
              disabled={dis}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder={placeholder || ""}
            />
            {flag === "rupee" && (
              <span
                className="notranslate"
                style={{
                  position: "absolute",
                  left: "12px",
                  color: "white",
                }}
              >
                ₹
              </span>
            )}
            {flag === "dollar" && (
              <span
                className="notranslate"
                style={{
                  position: "absolute",
                  left: "12px",
                  color: "white",
                }}
              >
                $
              </span>
            )}
            {flag === "euro" && (
              <span
                className="notranslate"
                style={{
                  position: "absolute",
                  left: "12px",
                  color: "white",
                }}
              >
                €
              </span>
            )}
            {flag === "pound" && (
              <span
                className="notranslate"
                style={{
                  position: "absolute",
                  left: "12px",
                  color: "white",
                }}
              >
                £
              </span>
            )}
            {(flag === "percentage" ||
              flag === "year" ||
              flag === "month" ||
              flag === "day" ||
              flag === "kilogram" ||
              flag === "centimeter" ||
              flag === "inches" ||
              flag === "feet" ||
              flag === "lb" ||
              flag === "hours") && (
              <span className="span-percentage notranslate">
                {flag === "percentage"
                  ? "%"
                  : flag === "year"
                  ? "yr"
                  : flag === "month"
                  ? "mon"
                  : flag === "day"
                  ? "day"
                  : flag === "kilogram"
                  ? "kg"
                  : flag === "centimeter"
                  ? "cm"
                  : flag === "feet"
                  ? "ft"
                  : flag === "inches"
                  ? "in"
                  : flag === "lb"
                  ? "lb"
                  : flag === "hours"
                  ? "hr"
                  : ""}
              </span>
            )}

            <input
              type="number"
              className="class-except-rupee-dollar"
              style={{
                cursor: dis ? "not-allowed" : "auto",
                width: "22%",
                margin: "0px 4px",
              }}
              id={label}
              onChange={(e) => {
                if (type === "number") {
                  if (0 > e.target.value || 999 < e.target.value) {
                    setOtherState();
                  } else {
                    setOtherState(e.target.value);
                  }
                } else {
                  setOtherState(e.target.value);
                }
              }}
              value={otherValue}
              defaultValue={otherValue}
              min={0}
              max={999}
              disabled={dis}
              onBlur={(e) => {
                if (type === "number") {
                  if (e.target.value < 0 || !e.target.value) {
                    setOtherState(0 ? 0 : 0);
                  } else if (e.target.value > 999) {
                    setOtherState(999);
                  } else if (e.target.value < 0) {
                    setOtherState(0);
                  }
                } else {
                  setOtherState(e.target.value);
                }
              }}
              onFocus={(e) => {
                if (e.target.value === "0") {
                  setOtherState("");
                }
              }}
              placeholder={placeholder || ""}
            />
            <span className="span-percentage notranslate">%</span>
          </Grid>
        </Grid>
      ) : (
        isInput && (
          <Grid className="input-div">
            <Typography className="input-label">{label}</Typography>
            {type === "text" ? (
              <Grid
                className="common-input-parent"
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  id={label}
                  style={{ padding: "12px" }}
                  name={label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  defaultValue={value}
                  value={value}
                  placeholder={placeholder || ""}
                />
              </Grid>
            ) : (
              <Grid
                className="common-input-parent"
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="number"
                  className={
                    flag === "rupee" || flag === "dollar"
                      ? "class-rupee-dollar"
                      : flag === "day" || flag === "month"
                      ? "class-except-month-day"
                      : "class-except-rupee-dollar"
                  }
                  style={{
                    cursor: dis ? "not-allowed" : "auto",
                  }}
                  id={label}
                  name={label}
                  onChange={handleChange}
                  value={value}
                  defaultValue={value}
                  min={min}
                  max={max}
                  disabled={dis}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder={placeholder || ""}
                />
                {flag === "rupee" && (
                  <span
                    className="notranslate"
                    style={{
                      position: "absolute",
                      left: "12px",
                      color: "white",
                    }}
                  >
                    ₹
                  </span>
                )}
                {flag === "dollar" && (
                  <span
                    className="notranslate"
                    style={{
                      position: "absolute",
                      left: "12px",
                      color: "white",
                    }}
                  >
                    $
                  </span>
                )}
                {flag === "euro" && (
                  <span
                    className="notranslate"
                    style={{
                      position: "absolute",
                      left: "12px",
                      color: "white",
                    }}
                  >
                    €
                  </span>
                )}
                {flag === "pound" && (
                  <span
                    className="notranslate"
                    style={{
                      position: "absolute",
                      left: "12px",
                      color: "white",
                    }}
                  >
                    £
                  </span>
                )}
                {(flag === "percentage" ||
                  flag === "year" ||
                  flag === "month" ||
                  flag === "day" ||
                  flag === "kilogram" ||
                  flag === "centimeter" ||
                  flag === "inches" ||
                  flag === "feet" ||
                  flag === "lb" ||
                  flag === "hours") && (
                  <span className="span-percentage notranslate">
                    {flag === "percentage"
                      ? "%"
                      : flag === "year"
                      ? "yr"
                      : flag === "month"
                      ? "mon"
                      : flag === "day"
                      ? "day"
                      : flag === "kilogram"
                      ? "kg"
                      : flag === "centimeter"
                      ? "cm"
                      : flag === "feet"
                      ? "ft"
                      : flag === "inches"
                      ? "in"
                      : flag === "lb"
                      ? "lb"
                      : flag === "hours"
                      ? "hr"
                      : ""}
                  </span>
                )}
              </Grid>
            )}
          </Grid>
        )
      )}
      {!isInput && (
        <Grid className="input-div">
          {label && <Typography className="label-value">{label}</Typography>}
          <Typography
            className="amount-value notranslate"
            sx={{ color: `${color} !important` }}
          >
            {flag === "percentage" ? (
              <>
                <span>{value}</span> <span>%</span>
              </>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: date
                    ? value
                    : formatAmountIndian(value, flag && flag),
                }}
              ></span>
            )}
          </Typography>
        </Grid>
      )}
    </>
  );
}

export default InputComponent;
