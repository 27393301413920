import * as mathjs from "mathjs";

// Simple Fractions Helper
export const calculateFractions = (x1, x2, x3, x4, x5) => {
    switch (x3) {
        case "-":
            return (x1 / x2) - (x4 / x5);
        case "X":
            return (x1 / x2) * (x4 / x5);
        case "/":
            return (x1 / x2) / (x4 / x5);
        case "+":
            return (x1 / x2) + (x4 / x5);
        default:
            return 0
    }
};


// Mixed Fractions Helper
export const calculateMixedNumbers = (y1, y2, y3, y4, y5, y6, y7) => {
    switch (y4) {
        case "-":
            return mixedFractionMinusHelper(y1, y2, y3, y5, y6, y7);
        case "X":
            return mixedFractionMultiplicationHelper(y1, y2, y3, y5, y6, y7);
        case "/":
            return mixedFractionDivisionHelper(y1, y2, y3, y5, y6, y7);
        case "+":
            return mixedNumberAdditionHelper(y1, y2, y3, y5, y6, y7);
        default:
            return 0
    }
}

const mixedNumberAdditionHelper = (y1, y2, y3, y5, y6, y7) => {
    let part1Sign = getSign([y1, y2, y3])
    let part2Sign = getSign([y5, y6, y7])

    let wholePartSum = (part1Sign * Math.abs(y1)) + (part2Sign * Math.abs(y5))
    let fractionPartSum = (part1Sign * Math.abs(y2 / y3)) + (part2Sign * Math.abs(y6 / y7))

    let result = (wholePartSum + fractionPartSum)

    return result

};

const mixedFractionMinusHelper = (y1, y2, y3, y5, y6, y7) => {
    let part1Sign = getSign([y1, y2, y3])
    let part2Sign = getSign([y5, y6, y7])

    let wholePartSum = (part1Sign * Math.abs(y1)) - (part2Sign * Math.abs(y5))
    let fractionPartSum = (part1Sign * Math.abs(y2 / y3)) - (part2Sign * Math.abs(y6 / y7))

    let result = (wholePartSum + fractionPartSum)

    return result
};

const mixedFractionMultiplicationHelper = (y1, y2, y3, y5, y6, y7) => {
    let sign = getSign([y1, y2, y3, y5, y6, y7])

    y1 = Math.abs(y1); y2 = Math.abs(y2); y3 = Math.abs(y3);
    y5 = Math.abs(y5); y6 = Math.abs(y6); y7 = Math.abs(y7);

    let r1 = ((y3 * y1) + y2) / y3
    let r2 = ((y7 * y5) + y6) / y7
    let result = (r1 * r2) * sign

    return result
};

const mixedFractionDivisionHelper = (y1, y2, y3, y5, y6, y7) => {
    let sign = getSign([y1, y2, y3, y5, y6, y7])

    y1 = Math.abs(y1); y2 = Math.abs(y2); y3 = Math.abs(y3);
    y5 = Math.abs(y5); y6 = Math.abs(y6); y7 = Math.abs(y7);

    let r1 = ((y3 * y1) + y2) / y3
    let r2 = ((y7 * y5) + y6) / y7
    let result = (r1 / r2) * sign

    return result;
};


// Simplify fractions
export const calculateSimplifyFractions = (z1, z2, z3) => {
    let sign = getSign([z1]);
    z1 = Math.abs(z1); 
    z2 = Math.abs(z2); 
    z3 = Math.abs(z3);

    // Convert to improper fraction
    let numerator = (z3 * z1) + z2;
    let denominator = z3;

    // Simplify the fraction
    let gcd = getGCD(numerator, denominator);
    numerator /= gcd;
    denominator /= gcd;

    // Apply sign to the fraction
    numerator *= sign;

    return denominator === 1 ? `${numerator}` : `${numerator}/${denominator}`;
}

// Function to calculate the Greatest Common Divisor (GCD)
const getGCD = (a, b) => {
    while (b !== 0) {
        let temp = b;
        b = a % b;
        a = temp;
    }
    return a;
}

// Decimato to fraction calculator
export const generateFraction = (p1) => {
    if (isNaN(p1)) return { n: 0, d: 1 }

    let fractionValue = mathjs.fraction(p1).toJSON()
    return { n: fractionValue.n, d: fractionValue.d }
}


export const generateDecimal = (q1, q2) => {
    if (!(Number.isInteger(q1) && Number.isInteger(q2))) return 0

    let fractionValue = mathjs.fraction(q1, q2)
    return mathjs.number(fractionValue)
}

// calculates a fraction for big fractions

export const generateBigFractions = (r1, r2, r3, r4, r5) => {

    // To prevenet mathjs NaN errors
    if (Number.isNaN(r1) || Number.isNaN(r2)
        || Number.isNaN(r3) || Number.isNaN(r4)) {
        return 0
    }

    switch (r3) {
        case "-":
            return bigSubtract(r1, r2, r4, r5)
        case "X":
            return bigMultiply(r1, r2, r4, r5)
        case "/":
            return bigDivide(r1, r2, r4, r5)
        case "+":
            return bigAdd(r1, r2, r4, r5)
        default:
            return 0
    }
}

const bigSubtract = (r1, r2, r4, r5) => {
    let f1 = mathjs.fraction(r1, r2)
    let f2 = mathjs.fraction(r4, r5)

    let result = mathjs.subtract(f1, f2).toJSON()

    return { n: result.n, d: result.d }

}

const bigAdd = (r1, r2, r4, r5) => {
    let f1 = mathjs.fraction(r1, r2)
    let f2 = mathjs.fraction(r4, r5)

    let result = mathjs.add(f1, f2).toJSON()

    return { n: result.n, d: result.d }

}

const bigMultiply = (r1, r2, r4, r5) => {
    let f1 = mathjs.fraction(r1, r2)
    let f2 = mathjs.fraction(r4, r5)

    let result = mathjs.multiply(f1, f2).toJSON()

    return { n: result.n, d: result.d }

}

const bigDivide = (r1, r2, r4, r5) => {
    let f1 = mathjs.fraction(r1, r2)
    let f2 = mathjs.fraction(r4, r5)

    let result = mathjs.divide(f1, f2).toJSON()

    return { n: result.n, d: result.d }
}


// Returns final sign (positive or negative) of a fraction
const getSign = (arr) => {
    return arr.reduce((acc, curr) => {
        if (acc === 1 && curr < 0) return -1

        else if (acc === -1 && curr < 0) return 1

        else return acc
    }, 1)
}
