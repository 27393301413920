import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import InputField from "../../Common/InputField/InputField";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import "./SquareRootCalc.scss";

function SquareRootCalc() {
  const [rootValue, setRootValue] = useState(4);
  const [rootValueAns, setRootValueAns] = useState(0);

  const [cubeValue, setCubeValue] = useState(27);
  const [cubeValueAns, setCubeValueAns] = useState(0);

  const [generalValueA, setGeneralValueA] = useState(5);
  const [generalValueB, setGeneralValueB] = useState(32);
  const [generalValueAns, setGeneralValueAns] = useState(0);

  useEffect(() => {
    const rootValueResult = Math.sqrt(rootValue);
    setRootValueAns(rootValueResult.toFixed(6));
  }, [rootValue]);

  useEffect(() => {
    const cubeValueResult = Math.cbrt(cubeValue);
    setCubeValueAns(cubeValueResult.toFixed(6));
  }, [cubeValue]);

  useEffect(() => {
    if (generalValueA > 0) {
      const result = Math.pow(generalValueB, 1 / generalValueA);
      setGeneralValueAns(result.toFixed(6));
    } else {
      setGeneralValueAns("Undefined");
    }
  }, [generalValueA, generalValueB]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. Square Root Calculator
            </Typography>
            <Grid className="input-div">
              <Box className="fraction-calc-wrap">
                <Box className="square-root-container">
                  <div className="square-root-symbol">√</div>
                  <InputField
                    value={rootValue}
                    type={"number"}
                    setState={(value) => setRootValue(parseInt(value))}
                  />
                </Box>

                <Box className="result-preview">
                  <Box>
                    <Typography>=</Typography>
                  </Box>
                  <Box>
                    <Typography>?</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={rootValueAns}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              2. Cube Root Calculator
            </Typography>
            <Grid className="input-div">
              <Box className="fraction-calc-wrap">
                <Box className="square-root-container">
                  <div className="square-root-symbol">∛</div>
                  <InputField
                    value={cubeValue}
                    type={"number"}
                    setState={(value) => setCubeValue(parseInt(value))}
                  />
                </Box>

                <Box className="result-preview">
                  <Box>
                    <Typography>=</Typography>
                  </Box>
                  <Box>
                    <Typography>?</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={cubeValueAns}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.8} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              3. General Root Calculator
            </Typography>
            <Grid className="input-div">
              <Box className="fraction-calc-wrap">
                <Box className="square-root-container">
                  <InputField
                    value={generalValueA}
                    type={"number"}
                    setState={(value) => setGeneralValueA(parseInt(value))}
                    className="general-root-input"
                  />
                  <div className="square-root-symbol">√</div>
                  <InputField
                    value={generalValueB}
                    type={"number"}
                    setState={(value) => setGeneralValueB(parseInt(value))}
                  />
                </Box>

                <Box className="result-preview">
                  <Box>
                    <Typography>=</Typography>
                  </Box>
                  <Box>
                    <Typography>?</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={generalValueAns}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default SquareRootCalc;
