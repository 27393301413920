import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import TableComponent from "../../Common/TableComponent/TableComponent";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function RMDCalc() {
  const [birthYear, setBirthYear] = useState(1949);
  const [rmdYear, setRmdYear] = useState("2024");
  const [accountBalance, setAccountBalance] = useState(200000);
  const [estimatedReturn, setEstimatedReturn] = useState(5);
  const [distributionPeriod, setDistributionPeriod] = useState("N/A");
  const [rmdValue, setRmdValue] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);

  useEffect(() => {
    const distributionPeriods = {
      73: 26.5,
      74: 25.5,
      75: 24.6,
      76: 23.7,
      77: 22.9,
      78: 22.0,
      79: 21.1,
      80: 20.2,
      81: 19.4,
      82: 18.5,
      83: 17.7,
      84: 16.8,
      85: 16.0,
      86: 15.2,
      87: 14.4,
      88: 13.7,
      89: 12.9,
      90: 12.2,
      91: 11.5,
      92: 10.8,
      93: 10.1,
      94: 9.5,
      95: 8.9,
      96: 8.4,
      97: 7.8,
      98: 7.3,
      99: 6.8,
      100: 6.4,
      101: 6.0,
      102: 5.6,
      103: 5.2,
      104: 4.9,
      105: 4.6,
      106: 4.3,
      107: 4.1,
      108: 3.9,
      109: 3.7,
      110: 3.5,
      111: 3.4,
      112: 3.3,
      113: 3.1,
      114: 3,
      115: 2.9,
      116: 2.8,
      117: 2.7,
      118: 2.5,
      119: 2.3,
      120: 2,
    };

    const startRMDYear = parseInt(birthYear) + 73;
    const endCalculationYear = startRMDYear + 47;
    let currentYear = parseInt(rmdYear);
    let currentAge = currentYear - parseInt(birthYear);
    let balance = parseFloat(accountBalance);

    const results = [];
    while (currentYear <= endCalculationYear) {
      if (currentAge < 73) {
        const endBalance = +balance * (1 + +estimatedReturn / 100);
        results.push({
          year: +currentYear,
          age: +currentAge,
          distributionPeriod: "N/A",
          rmd: 0,
          endOfYearBalance: +endBalance.toFixed(2),
        });
        balance = +endBalance;
      } else if (distributionPeriods[currentAge]) {
        const distPeriod = distributionPeriods[currentAge];
        const rmd = +balance / +distPeriod;
        const endBalance =
          +balance + (+balance * +estimatedReturn) / 100 - +rmd;

        results.push({
          year: +currentYear,
          age: +currentAge,
          distributionPeriod: +distPeriod.toFixed(1),
          rmd: +rmd.toFixed(2),
          endOfYearBalance: endBalance,
        });
        balance = endBalance;
      } else {
        results.push({
          year: +currentYear,
          age: +currentAge,
          distributionPeriod: "N/A",
          rmd: 0,
          endOfYearBalance: +balance,
        });
      }

      currentYear++;
      currentAge++;
    }

    setMonthlyRecords(results);
    setRmdValue(results[0]?.age < 73 ? 0 : results[0]?.rmd);
    setDistributionPeriod(
      results[0]?.age < 73 ? "N/A" : results[0]?.distributionPeriod
    );
  }, [birthYear, rmdYear, accountBalance, estimatedReturn]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                label="Your year of birth"
                value={birthYear}
                setState={setBirthYear}
                min={1910}
                max={2026}
              />
              <SelectBoxComponent
                label={"Year of RMD"}
                value={rmdYear}
                setValue={setRmdYear}
                data={["2025", "2024", "2023", "2022"]}
                placeholder="Select year of RMD"
              />
              <InputComponent
                isInput={true}
                flag="dollar"
                label={`Account balance as of 12/31/${rmdYear - 1}`}
                value={accountBalance}
                setState={setAccountBalance}
                min={0}
                max={10000000000}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Estimated rate of return (Optional) "
                setState={setEstimatedReturn}
                value={estimatedReturn}
                min={0}
                max={100}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="distribution period for your case"
                isInput={false}
                value={distributionPeriod}
                date={true}
              />
              <InputComponent
                label={`RMD for ${rmdYear}`}
                isInput={false}
                value={rmdValue}
                flag={"dollar"}
              />
              <Typography className="gpa-final-result">
                {monthlyRecords[0]?.age < 73
                  ? `If there are no policy changes, your RMD will begin in ${
                      +birthYear + 73
                    } when you turn 73.`
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">RMD Table</Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  headerData={[
                    "Year",
                    "Your Age",
                    "Distribution period",
                    "RMD",
                    "End of Year Balance",
                  ]}
                  currency="dollar"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} className="piechart-div">
          <BarChartComponent
            data={[
              {
                data: monthlyRecords?.length
                  ? monthlyRecords.map((record) => record?.endOfYearBalance)
                  : [0],
                stack: "A",
                label: "Balance",
              },
              {
                data: monthlyRecords?.length
                  ? monthlyRecords.map((record) => record?.rmd)
                  : [0],
                stack: "A",
                label: "RMD",
              },
            ]}
            xLabels={
              monthlyRecords?.length
                ? monthlyRecords?.map((record) => record?.age)
                : [0]
            }
          />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default RMDCalc;
