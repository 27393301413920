import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import "../PercentageCalc/PercentageCalc.scss";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

function HexCalc() {
  const [firstValue, setFirstValue] = useState("8AB");
  const [secondValue, setSecondValue] = useState("B78");
  const [operator, setOperator] = useState("+");
  const [hexVal, setHexVal] = useState();
  const [decimalVal, setDecimalVal] = useState();

  const [hexVal2, setHexVal2] = useState("DAD");
  const [decimalVal2, setDecimalVal2] = useState(0);

  const [decimalVal3, setDecimalVal3] = useState(125);
  const [hexVal3, setHexVal3] = useState();

  useEffect(() => {
    const calculateResult = () => {
      if (!firstValue || !secondValue) {
        setHexVal("Invalid Input");
        setDecimalVal("Invalid Input");
        return;
      }

      const firstDec = parseInt(firstValue, 16);
      const secondDec = parseInt(secondValue, 16);

      if (isNaN(firstDec) || isNaN(secondDec)) {
        setHexVal("Invalid Hex Value");
        setDecimalVal("Invalid Hex Value");
        return;
      }

      let decimalResult;
      switch (operator) {
        case "+":
          decimalResult = firstDec + secondDec;
          break;
        case "-":
          decimalResult = firstDec - secondDec;
          break;
        case "*":
          decimalResult = firstDec * secondDec;
          break;
        case "/":
          if (secondDec === 0) {
            setHexVal("Error: Division by Zero");
            setDecimalVal("Error: Division by Zero");
            return;
          }
          decimalResult = Math.floor(firstDec / secondDec);
          break;
        default:
          setHexVal("Invalid Operator");
          setDecimalVal("Invalid Operator");
          return;
      }

      const hexResult = decimalResult.toString(16).toUpperCase();

      setHexVal(hexResult);
      setDecimalVal(decimalResult);
    };

    calculateResult();
  }, [firstValue, secondValue, operator]);

  useEffect(() => {
    if (hexVal2) {
      try {
        const decimal = parseInt(hexVal2, 16);
        if (!isNaN(decimal)) {
          setDecimalVal2(decimal);
        } else {
          setDecimalVal2("Invalid Hex");
        }
      } catch (error) {
        setDecimalVal2("Invalid Hex");
      }
    } else {
      setDecimalVal2("");
    }
  }, [hexVal2]);

  useEffect(() => {
    if (decimalVal3) {
      try {
        const decimal = parseInt(decimalVal3, 10);
        if (!isNaN(decimal)) {
          setHexVal3(decimal.toString(16).toUpperCase());
        } else {
          setHexVal3("Invalid Decimal");
        }
      } catch (error) {
        setHexVal3("Invalid Decimal");
      }
    } else {
      setHexVal3("");
    }
  }, [decimalVal3]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              Hexadecimal Calculation—Add, Subtract, Multiply, or Divide
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                type="text"
                isInput={true}
                label="First Hexadecimal value"
                value={firstValue}
                setState={setFirstValue}
              />
              <SelectBoxComponent
                value={operator}
                setValue={setOperator}
                data={["+", "-", "*", "/"]}
                label="Select Operator"
              />
              <InputComponent
                type="text"
                isInput={true}
                label="Second Hexadecimal value"
                value={secondValue}
                setState={setSecondValue}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Hex value"
                isInput={false}
                value={hexVal}
                date={true}
              />
              <InputComponent
                label="Decimal value"
                isInput={false}
                value={decimalVal}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              Convert Hexadecimal Value to Decimal Value
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                type="text"
                isInput={true}
                label="Hexadecimal value"
                value={hexVal2}
                setState={setHexVal2}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Decimal value"
                isInput={false}
                value={decimalVal2}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              Convert Decimal Value to Hexadecimal Value
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                type="text"
                isInput={true}
                label="Decimal value"
                value={decimalVal3}
                setState={setDecimalVal3}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Hexadecimal value"
                isInput={false}
                value={hexVal3}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default HexCalc;
