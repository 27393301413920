import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import "../GPACalc/Table.scss";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { GENDERS } from "../../../Utils/calorieHelper";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

export default function BACCalc() {
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [weightValue, setWeightValue] = useState(70);
  const [weightUnit, setWeightUnit] = useState("kg");
  const [firstDrinkHours, setFirstDrinkHours] = useState(2);
  const [firstDrinkMinutes, setFirstDrinkMinutes] = useState(2);

  const [beerAmount, setBeerAmount] = useState(2);

  const [wineAmount, setWineAmount] = useState(0);

  const [liquorAmount, setLiquorAmount] = useState(0);

  const [bac, setBac] = useState(0);

  useEffect(() => {
    const calculateBAC = () => {
      const weightInPounds =
        weightUnit === "kg" ? weightValue * 2.20462 : weightValue;

      const r = gender.value === "male" ? 0.68 : 0.55;

      const beerAlcohol = beerAmount * (12 * 0.05);
      const wineAlcohol = wineAmount * (5 * 0.12);
      const liquorAlcohol = liquorAmount * (1.5 * 0.4);
      const totalAlcohol = beerAlcohol + wineAlcohol + liquorAlcohol;

      const timeElapsed = firstDrinkHours + firstDrinkMinutes / 60;

      let calculatedBAC = 0;

      if (weightInPounds > 0 && totalAlcohol > 0) {
        calculatedBAC =
          (totalAlcohol * 6.24) / (weightInPounds * r) - 0.015 * timeElapsed;
        calculatedBAC = Math.max(0, calculatedBAC);
      }

      setBac(calculatedBAC.toFixed(2));
    };

    calculateBAC();
  }, [
    gender,
    weightValue,
    weightUnit,
    firstDrinkHours,
    firstDrinkMinutes,
    beerAmount,
    wineAmount,
    liquorAmount,
  ]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (/^\d*(\.\d*)?$/.test(newValue)) {
      if (newValue === "" || (newValue >= 0 && newValue <= 200)) {
        setWeightValue(newValue);
      }
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Grid item className="calculation-part ">
              <Grid item>
                <RadioButtonComponent
                  label={"Gender"}
                  data={GENDERS}
                  defaultValue={"male"}
                  valueSetter={setGender}
                  row={true}
                />
                <Grid>
                  <Grid className="concrete-calc-input pace">
                    <Typography className="input-label">Body Weight</Typography>
                    <Grid sx={{ display: "flex" }}>
                      <input
                        type="text"
                        value={weightValue}
                        onChange={handleInputChange}
                        className="input-field"
                        max={200}
                        min={0}
                      />

                      <Grid className="select-box-wrap">
                        <SelectBoxComponent
                          value={weightUnit}
                          setValue={setWeightUnit}
                          data={["kg", "lb"]}
                          placeholder="Select weight unit"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <InputComponent
                  isInput={true}
                  flag="hours"
                  label="Time Since First Drink Hours"
                  value={firstDrinkHours}
                  min={1}
                  max={100}
                  setState={setFirstDrinkHours}
                />
                <InputComponent
                  isInput={true}
                  flag="hours"
                  label="Time Since First Drink Minutes"
                  value={firstDrinkMinutes}
                  min={1}
                  max={100}
                  setState={setFirstDrinkMinutes}
                />

                <InputComponent
                  isInput={true}
                  label="Beer 12oz - 5% ABV"
                  value={beerAmount}
                  min={0}
                  max={100}
                  setState={setBeerAmount}
                />

                <InputComponent
                  isInput={true}
                  label="Wine 5oz - 12% ABV"
                  value={wineAmount}
                  min={0}
                  max={100}
                  setState={setWineAmount}
                />

                <InputComponent
                  isInput={true}
                  label="Liquor 1.5oz - 40% ABV"
                  value={liquorAmount}
                  min={0}
                  max={100}
                  setState={setLiquorAmount}
                />
              </Grid>
              <Grid className="result-label">
                <Typography>Result</Typography>
              </Grid>
              <Grid className="result-common-div">
                <InputComponent
                  label="BAC is"
                  isInput={false}
                  value={bac || 0}
                  date={true}
                  flag={"percentage"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
