export const BMR_FORMULAS = [
  { label: "Mifflin-St Jeor", value: "MJ" },
  { label: "Revised Harris-Benedict", value: "HB" },
  { label: "Katch-McArdle", value: "KM" },
];
export const CARBO_FORMULAS = [
  { label: "Mifflin-St Jeor", value: "MJ" },
  { label: "Katch-McArdle", value: "KM" },
];
export const ENERGY_UNITS = [
  { label: "Calories", value: "cal" },
  { label: "Kilojules", value: "kj" },
];
export const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const BondConversion = [
  { label: "30/360", value: "30/360" },
  { label: "Actual/360", value: "Actual/360" },
  { label: "Actual/365", value: "Actual/365" },
  { label: "Actual/Actual", value: "Actual/Actual" },
];

export const InterestTypeIntoLease = [
  { label: "Interest Rate ( % )", value: "InterestR" },
  { label: "Money Factory", value: "moneyF" },
];
export const AnnuityCalcRadio = [
  { label: "beginning (annuity due)", value: "began" },
  { label: "end (ordinary/immediate annuity) ", value: "end" },
];

export const creditCardCalcRadio = [
  { label: "Pay a certain amount", value: "certain" },
  { label: "Payoff within a certain timeframe", value: "timeframe" },
];

export const personalLoanRadio = [
  { label: "deducted from loan", value: "intoLoan" },
  { label: "paid upfront", value: "upfront" },
];
export const BOOLEANS = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];
export const WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const UNIT_NAMES = [
  "Calorie [Nutritional,  kcal]",
  "calorie [cal]",
  "Kilojoules [kJ]",
  "joules [J]",
];

export const VAEligibility = [
  { label: "Active/Veteran", value: "activeV" },
  { label: "Reservist/National Guard", value: "reservistN" },
  { label: "Surviving Spouses", value: "survivingS" },
];

export const VAFundingFee = [
  { label: "Financed Into Loan", value: "FinancedLoan" },
  { label: "Paid Upfront", value: "PaidUpfront" },
];

export const ACTIVITY = [
  {
    name: "Basal Metabolic Rate (BMR)",
    value: "bmr",
    goals: [{ name: "Maintain weight", value: "maintain", multiplier: 1 }],
  },
  {
    name: "Sedentary: little or no exercise",
    value: "sedentary",
    goals: [
      { name: "Maintain weight", value: "maintain", multiplier: 1.2 },
      {
        name: "Mild weight loss (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.049,
      },
      { name: "Weight loss (0.5 kg/week)", value: "WL", multiplier: 0.899 },
      {
        name: "Extreme weight loss (1 kg/week)",
        value: "extremeWL",
        multiplier: 0.597,
      },
    ],
  },
  {
    name: "Light: exercise 1-3 times/week",
    value: "light",
    goals: [
      { name: "Maintain weight", value: "maintain", multiplier: 1.375 },
      {
        name: "Mild weight loss (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.224,
      },
      { name: "Weight loss (0.5 kg/week)", value: "WL", multiplier: 1.073 },
      {
        name: "Extreme weight loss (1 kg/week)",
        value: "extremeWL",
        multiplier: 0.771,
      },
    ],
  },
  {
    name: "Moderate: exercise 4-5 times/week",
    value: "moderate",
    goals: [
      { name: "Maintain weight", value: "maintain", multiplier: 1.465 },
      {
        name: "Mild weight loss (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.314,
      },
      { name: "Weight loss (0.5 kg/week)", value: "WL", multiplier: 1.163 },
      {
        name: "Extreme weight loss (1 kg/week)",
        value: "extremeWL",
        multiplier: 0.862,
      },
    ],
  },
  {
    name: "Active: daily exercise or intense exercise 3-4 times/week",
    value: "active",
    goals: [
      { name: "Maintain weight", value: "maintain", multiplier: 1.55 },
      {
        name: "Mild weight loss (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.399,
      },
      { name: "Weight loss (0.5 kg/week)", value: "WL", multiplier: 1.248 },
      {
        name: "Extreme weight loss (1 kg/week)",
        value: "extremeWL",
        multiplier: 0.947,
      },
    ],
  },
  {
    name: "Very Active: intense exercise 6-7 times/week",
    value: "veryactive",
    goals: [
      { name: "Maintain weight", value: "maintain", multiplier: 1.725 },
      {
        name: "Mild weight loss (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.574,
      },
      { name: "Weight loss (0.5 kg/week)", value: "WL", multiplier: 1.423 },
      {
        name: "Extreme weight loss (1 kg/week)",
        value: "extremeWL",
        multiplier: 1.122,
      },
    ],
  },
  {
    name: "Extra Active: very intense exercise daily, or physical job",
    value: "extractive",
    goals: [
      { name: "Maintain weight", value: "maintain", multiplier: 1.9 },
      {
        name: "Mild weight loss (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.749,
      },
      { name: "Weight loss (0.5 kg/week)", value: "WL", multiplier: 1.598 },
      {
        name: "Extreme weight loss (1 kg/week)",
        value: "extremeWL",
        multiplier: 1.297,
      },
    ],
  },
];

export const GAINACTIVITY = [
  {
    name: "Basal Metabolic Rate (BMR)",
    value: "bmr",
    goals: [{ name: "Maintain weight", value: "maintain", multiplier: 1 }],
  },
  {
    name: "Sedentary: little or no exercise",
    value: "sedentary",
    goals: [
      {
        name: "Mild weight gain (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.35,
      },
      { name: "Weight gain (0.5 kg/week)", value: "WL", multiplier: 1.501 },
      {
        name: "Fast weight gain (1 kg/week)",
        value: "extremeWL",
        multiplier: 1.803,
      },
    ],
  },
  {
    name: "Light: exercise 1-3 times/week",
    value: "light",
    goals: [
      {
        name: "Mild weight gain (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.526,
      },
      { name: "Weight gain (0.5 kg/week)", value: "WL", multiplier: 1.677 },
      {
        name: "Fast weight gain (1 kg/week)",
        value: "extremeWL",
        multiplier: 1.978,
      },
    ],
  },
  {
    name: "Moderate: exercise 4-5 times/week",
    value: "moderate",
    goals: [
      {
        name: "Mild weight gain (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.615,
      },
      { name: "Weight gain (0.5 kg/week)", value: "WL", multiplier: 1.766 },
      {
        name: "Fast weight gain (1 kg/week)",
        value: "extremeWL",
        multiplier: 2.068,
      },
    ],
  },
  {
    name: "Active: daily exercise or intense exercise 3-4 times/week",
    value: "active",
    goals: [
      {
        name: "Mild weight gain (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.7,
      },
      { name: "Weight gain (0.5 kg/week)", value: "WL", multiplier: 1.851 },
      {
        name: "Fast weight gain (1 kg/week)",
        value: "extremeWL",
        multiplier: 2.153,
      },
    ],
  },
  {
    name: "Very Active: intense exercise 6-7 times/week",
    value: "veryactive",
    goals: [
      {
        name: "Mild weight gain (0.25 kg/week)",
        value: "mildWL",
        multiplier: 1.875,
      },
      { name: "Weight gain (0.5 kg/week)", value: "WL", multiplier: 2.026 },
      {
        name: "Fast weight gain (1 kg/week)",
        value: "extremeWL",
        multiplier: 2.328,
      },
    ],
  },
  {
    name: "Extra Active: very intense exercise daily, or physical job",
    value: "extractive",
    goals: [
      {
        name: "Mild weight gain (0.25 kg/week)",
        value: "mildWL",
        multiplier: 2.05,
      },
      { name: "Weight gain (0.5 kg/week)", value: "WL", multiplier: 2.201 },
      {
        name: "Fast weight gain (1 kg/week)",
        value: "extremeWL",
        multiplier: 2.503,
      },
    ],
  },
];

export const CALORIE_NEEDS = [
  {
    activity: "Sedentary: little or no exercise",
    value: "sedentary",
    multiplier: 1.199,
  },
  {
    activity: "Exercise 1-3 times/week",
    value: "1to3",
    multiplier: 1.374,
  },
  {
    activity: "Exercise 4-5 times/week",
    value: "4to5",
    multiplier: 1.464,
  },
  {
    activity: "Daily exercise or intense exercise 3-4 times/week",
    value: "intense3to4",
    multiplier: 1.549,
  },
  {
    activity: "Intense exercise 6-7 times/week",
    value: "intense6to7",
    multiplier: 1.724,
  },
  {
    activity: "Very intense exercise daily, or physical job",
    value: "veryintense",
    multiplier: 1.899,
  },
];

export const calculateBMR = (
  weight,
  height,
  age,
  fat,
  formulaName,
  gender,
  energyUnit
) => {
  let BMRInCalorie;
  if (formulaName === "MJ") {
    let constant = gender === "male" ? 5 : -165;
    BMRInCalorie = 10 * weight + 6.25 * height - 5 * age + constant;
  } else if (formulaName === "HB") {
    if (gender === "male") {
      BMRInCalorie = 13.397 * weight + 4.799 * height - 5.677 * age + 88.362;
    } else {
      BMRInCalorie = 9.247 * weight + 3.098 * height - 4.33 * age + 447.593;
    }
  } else if (formulaName === "KM") {
    BMRInCalorie = 370 + 21.6 * (1 - fat) * weight;
  }

  if (energyUnit === "kj") {
    return BMRInCalorie * 4.184;
  }
  return BMRInCalorie;
};

export const generateCalorieNeeds = (BMR) => {
  let tableData = [];

  CALORIE_NEEDS.forEach((item) => {
    tableData.push({
      activity: item.activity,
      energy: (BMR * item.multiplier).toFixed(0),
    });
  });

  return tableData;
};

export const generateActivityNeeds = (BMR, userActivity, flag) => {
  let tableData = [];

  let activityGoals = ACTIVITY.find(
    (activity) => activity.value === userActivity
  ).goals;
  if (flag === "TDCALC") {
    activityGoals = activityGoals.filter(
      (x) => x.value !== "extremeWL" && x.value !== "maintain"
    );
  }
  activityGoals.forEach((goal) => {
    tableData.push({
      goal: goal.name,
      energy: (BMR * goal.multiplier).toFixed(0),
    });
  });

  return tableData;
};

export const generateGainActivityNeeds = (BMR, userActivity) => {
  let tableData = [];

  let activityGoals = GAINACTIVITY.find(
    (activity) => activity.value === userActivity
  ).goals;
  activityGoals.forEach((goal) => {
    tableData.push({
      goal: goal.name,
      energy: (BMR * goal.multiplier).toFixed(0),
    });
  });

  return tableData;
};

export const generateDiet1 = (maintainingCalories) => {
  let tableData = [];

  WEEK.forEach((day) => {
    if (day === "Sunday" || day === "Saturday") {
      tableData.push({
        day: day,
        mildWL: maintainingCalories,
        WL: maintainingCalories,
        extremeWL: maintainingCalories,
      });
    } else {
      tableData.push({
        day: day,
        mildWL: (maintainingCalories * 0.85).toFixed(0),
        WL: (maintainingCalories * 0.7).toFixed(0),
        extremeWL: (maintainingCalories * 0.6).toFixed(0),
      });
    }
  });

  return tableData;
};

export const generateDiet2 = (maintainingCalories) => {
  let tableData = [];

  WEEK.forEach((day) => {
    if (day === "Sunday" || day === "Saturday") {
      tableData.push({
        day: day,
        mildWL: maintainingCalories,
        WL: maintainingCalories,
        extremeWL: maintainingCalories,
      });
    } else {
      tableData.push({
        day: day,
        mildWL: (maintainingCalories * 0.8).toFixed(0),
        WL: (maintainingCalories * 0.73).toFixed(0),
        extremeWL: (maintainingCalories * 0.64).toFixed(0),
      });
    }
  });

  return tableData;
};

export const unitConverter = (unit1, unit2) => {
  let result;
  // Block 1
  if (
    unit1.unit === "Calorie [Nutritional,  kcal]" &&
    unit2.unit === "Calorie [Nutritional,  kcal]"
  ) {
    result = unit1.value;
  } else if (
    unit1.unit === "Calorie [Nutritional,  kcal]" &&
    unit2.unit === "calorie [cal]"
  ) {
    result = unit1.value * 1000;
  } else if (
    unit1.unit === "Calorie [Nutritional,  kcal]" &&
    unit2.unit === "Kilojoules [kJ]"
  ) {
    result = unit1.value * 4.1868;
  } else if (
    unit1.unit === "Calorie [Nutritional,  kcal]" &&
    unit2.unit === "joules [J]"
  ) {
    result = unit1.value * 4186.8;
  }

  //Block 2
  else if (
    unit1.unit === "calorie [cal]" &&
    unit2.unit === "Calorie [Nutritional,  kcal]"
  ) {
    result = unit1.value * 0.001;
  } else if (unit1.unit === "calorie [cal]" && unit2.unit === "calorie [cal]") {
    result = unit1.value * 1;
  } else if (
    unit1.unit === "calorie [cal]" &&
    unit2.unit === "Kilojoules [kJ]"
  ) {
    result = unit1.value * 0.0041868;
  } else if (unit1.unit === "calorie [cal]" && unit2.unit === "joules [J]") {
    result = unit1.value * 4.1868;
  }

  //Block 3
  else if (
    unit1.unit === "Kilojoules [kJ]" &&
    unit2.unit === "Calorie [Nutritional,  kcal]"
  ) {
    result = unit1.value * 0.2388459;
  } else if (
    unit1.unit === "Kilojoules [kJ]" &&
    unit2.unit === "calorie [cal]"
  ) {
    result = unit1.value * 238.8459;
  } else if (
    unit1.unit === "Kilojoules [kJ]" &&
    unit2.unit === "Kilojoules [kJ]"
  ) {
    result = unit1.value * 1;
  } else if (unit1.unit === "Kilojoules [kJ]" && unit2.unit === "joules [J]") {
    result = unit1.value * 1000;
  }

  //Block 4
  else if (
    unit1.unit === "joules [J]" &&
    unit2.unit === "Calorie [Nutritional,  kcal]"
  ) {
    result = unit1.value * 0.0002388458966275;
  } else if (unit1.unit === "joules [J]" && unit2.unit === "calorie [cal]") {
    result = unit1.value * 0.2388459;
  } else if (unit1.unit === "joules [J]" && unit2.unit === "Kilojoules [kJ]") {
    result = unit1.value * 0.001;
  } else if (unit1.unit === "joules [J]" && unit2.unit === "joules [J]") {
    result = unit1.value * 1;
  }

  return result;
};

export const MACRO_FORMULAS = [
  { label: "Mifflin-St Jeor", value: "MJ" },
  { label: "Katch-McArdle", value: "KM" },
];
