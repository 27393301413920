import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import "./PercentageCalc.scss";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function PercentageCalc() {
  const [valueX1, setValueX1] = useState(0);
  const [valueX2, setValueX2] = useState(0);
  const [ansX, setAnsX] = useState(0);

  const [valueY1, setValueY1] = useState(0);
  const [valueY2, setValueY2] = useState(0);
  const [ansY, setAnsY] = useState(0);

  const [valueZ1, setValueZ1] = useState(0);
  const [valueZ2, setValueZ2] = useState(0);
  const [ansZ, setAnsZ] = useState(0);

  const [valueP1, setValueP1] = useState(0);
  const [valueP2, setValueP2] = useState(0);
  const [ansP, setAnsP] = useState(0);

  const [valueR1, setValueR1] = useState(0);
  const [valueR2, setValueR2] = useState(0);
  const [ansR, setAnsR] = useState(0);

  const [valueQ1, setValueQ1] = useState(0);
  const [valueQ2, setValueQ2] = useState(0);
  const [ansQ, setAnsQ] = useState(0);

  useEffect(() => {
    setAnsX((valueX2 / 100) * valueX1);
  }, [valueX1, valueX2]);

  useEffect(() => {
    if (valueY1 > 0 && valueY2 > 0) {
      setAnsY(`${((valueY1 / valueY2) * 100).toFixed(2)}%`);
    } else {
      setAnsY("0%");
    }
  }, [valueY1, valueY2]);

  useEffect(() => {
    if (valueZ1 > 0 && valueZ2 > 0) {
      setAnsZ(((valueZ1 * 100) / valueZ2).toFixed(2));
    } else {
      setAnsZ("0.00");
    }
  }, [valueZ1, valueZ2]);

  useEffect(() => {
    if (valueP1 === valueP2) {
      setAnsP("No change: " + valueP2 + " is equal to " + valueP1);
    } else {
      var difference = valueP2 - valueP1;
      var percentage = (Math.abs(difference) / valueP1) * 100;
      var message = "";
      if (difference < 0) {
        message =
          valueP2 + " is " + percentage.toFixed(2) + "% decrease of " + valueP1;
      } else {
        message =
          valueP2 + " is " + percentage.toFixed(2) + "% increase of " + valueP1;
      }
      setAnsP(message);
    }
  }, [valueP1, valueP2]);

  useEffect(() => {
    setAnsR((valueR1 * (1 + valueR2 / 100)).toFixed(2));
  }, [valueR1, valueR2]);

  useEffect(() => {
    setAnsQ((valueQ1 * (1 - valueQ2 / 100)).toFixed(2));
  }, [valueQ1, valueQ2]);
  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              1. What is <span>X</span> of <span>Y%</span> = Result
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                isInput={true}
                label="Insert Value of X"
                value={valueX1}
                setState={setValueX1}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Insert Value of Y"
                value={valueX2}
                setState={setValueX2}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansX}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              2.<span> X</span> is what % of <span>Y</span>
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                isInput={true}
                label="Insert Value of X"
                value={valueY1}
                setState={setValueY1}
              />
              <InputComponent
                isInput={true}
                label="Insert Value of Y"
                value={valueY2}
                setState={setValueY2}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansY}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              3. <span>X</span> is <span>Y%</span> of what ?
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                isInput={true}
                label="Insert Value of X"
                value={valueZ1}
                setState={setValueZ1}
              />
              <InputComponent
                isInput={true}
                label="Insert Value of Y"
                flag="percentage"
                value={valueZ2}
                setState={setValueZ2}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansZ}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              4. Percentage Difference Calculator for X and Y
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                isInput={true}
                label="Insert Value of X"
                value={valueP1}
                setState={setValueP1}
              />
              <InputComponent
                isInput={true}
                label="Insert Value of Y"
                value={valueP2}
                setState={setValueP2}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid>
              <InputComponent
                label="Result"
                isInput={false}
                value={ansP}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              5. X Increase Y% = Result
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                isInput={true}
                label="Insert Value of X"
                value={valueR1}
                setState={setValueR1}
              />
              <InputComponent
                isInput={true}
                label="Insert Value of Y"
                flag="percentage"
                value={valueR2}
                setState={setValueR2}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansR}
                date={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5.5} className="calculation-part">
            <Typography className="common-sub-heading-calc">
              6. X Decrease Y% = Result
            </Typography>
            <Grid className="percentage-common-input-div">
              <InputComponent
                isInput={true}
                label="Insert Value of X"
                value={valueQ1}
                setState={setValueQ1}
              />
              <InputComponent
                isInput={true}
                label="Insert Value of Y"
                flag="percentage"
                value={valueQ2}
                setState={setValueQ2}
                min={0}
                max={999}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>
            <Grid className="result-common-div">
              <InputComponent
                label="Result"
                isInput={false}
                value={ansQ}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default PercentageCalc;
