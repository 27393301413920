import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import DatePickerCommon from "../../Common/Datepicker/DatePickerCommon";

const TimeBetweenDate = () => {
  const [hour1, setHour1] = useState(8);
  const [minute1, setMinute1] = useState(30);
  const [second1, setSecond1] = useState(0);
  const [amOrPm1, setAmOrPm1] = useState("AM");

  const [hour2, setHour2] = useState(5);
  const [minute2, setMinute2] = useState(30);
  const [second2, setSecond2] = useState(0);
  const [amOrPm2, setAmOrPm2] = useState("PM");

  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();

  const [formattedTime, setFormattedTime] = useState("");
  const [fractionOfDays, setFractionOfDays] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  useEffect(() => {
    if (!date1 || !date2) return;

    const startTime = moment(
      `${date1.format(
        "YYYY-MM-DD"
      )} ${+hour1}:${+minute1}:${+second1} ${amOrPm1}`,
      "YYYY-MM-DD h:mm:ss A"
    );
    const endTime = moment(
      `${date2.format(
        "YYYY-MM-DD"
      )} ${+hour2}:${+minute2}:${+second2} ${amOrPm2}`,
      "YYYY-MM-DD h:mm:ss A"
    );

    if (endTime.isBefore(startTime)) {
      setFormattedTime("End time cannot be earlier than start time.");
      setFractionOfDays(0);
      setTotalHours(0);
      setTotalMinutes(0);
      setTotalSeconds(0);
      return;
    }

    const duration = moment.duration(endTime.diff(startTime));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const totalHours = duration.asHours();
    const totalMinutes = duration.asMinutes();
    const totalSeconds = duration.asSeconds();
    const fractionalDays = duration.asDays().toFixed(3);

    const formattedTime = `${days} day${days !== 1 ? "s" : ""}, ${hours} hour${
      hours !== 1 ? "s" : ""
    }, ${minutes} minute${minutes !== 1 ? "s" : ""}, and ${seconds} second${
      seconds !== 1 ? "s" : ""
    }`;

    setFormattedTime(formattedTime);
    setFractionOfDays(
      `${fractionalDays} day${fractionalDays !== "1" ? "s" : ""}`
    );
    setTotalHours(`${+totalHours.toFixed(2)} hours`);
    setTotalMinutes(`${+totalMinutes.toFixed(2)} minutes`);
    setTotalSeconds(`${Math.round(+totalSeconds)} seconds`);
  }, [
    hour1,
    minute1,
    second1,
    amOrPm1,
    hour2,
    minute2,
    second2,
    amOrPm2,
    date1,
    date2,
  ]);

  return (
    <Grid item xs={12} md={5.9} className="calculation-part">
      <Typography className="common-sub-heading-calc">
        2. Time Between Two Dates
      </Typography>
      <Typography className="common-sub-heading-desc">Start Time:</Typography>
      <DatePickerCommon label="Start Date" value={date1} setValue={setDate1} />
      <InputComponent
        isInput={true}
        label="Hour"
        max={12}
        value={hour1}
        setState={setHour1}
      />
      <InputComponent
        isInput={true}
        label="Minute"
        max={59}
        value={minute1}
        setState={setMinute1}
      />
      <InputComponent
        isInput={true}
        label="Second"
        max={59}
        value={second1}
        setState={setSecond1}
      />
      <SelectBoxComponent
        value={amOrPm1}
        setValue={setAmOrPm1}
        data={["AM", "PM"]}
        placeholder="Select AM or PM"
      />
      <Typography className="common-sub-heading-desc">End Time:</Typography>
      <DatePickerCommon label="End Date" value={date2} setValue={setDate2} />
      <InputComponent
        isInput={true}
        label="Hour"
        max={12}
        value={hour2}
        setState={setHour2}
      />
      <InputComponent
        isInput={true}
        label="Minute"
        max={59}
        value={minute2}
        setState={setMinute2}
      />
      <InputComponent
        isInput={true}
        label="Second"
        max={59}
        value={second2}
        setState={setSecond2}
      />
      <SelectBoxComponent
        value={amOrPm2}
        setValue={setAmOrPm2}
        data={["AM", "PM"]}
        placeholder="Select AM or PM"
      />
      <Grid className="result-label">
        <Typography>Result</Typography>
      </Grid>
      <Grid>
        <InputComponent
          isInput={false}
          label="Total Time"
          value={formattedTime}
          date={true}
        />
        <InputComponent
          isInput={false}
          label="Total Days"
          value={fractionOfDays}
          date={true}
        />
        <InputComponent
          isInput={false}
          label="Total Hours"
          value={totalHours}
          date={true}
        />
        <InputComponent
          isInput={false}
          label="Total Minutes"
          value={totalMinutes}
          date={true}
        />
        <InputComponent
          isInput={false}
          label="Total Seconds"
          value={totalSeconds}
          date={true}
        />
      </Grid>
    </Grid>
  );
};

export default TimeBetweenDate;
