import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import "./MatrixCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";
import * as mlMatrix from "ml-matrix";
import { inverse, determinant } from "ml-matrix";
import "katex/dist/katex.min.css";
import { BlockMath } from "react-katex";

function MatrixCalc() {
  const [rows1, setRows1] = useState(4);
  const [columns1, setColumns1] = useState(4);
  const [matrix1, setMatrix1] = useState(
    Array.from({ length: 4 }, () => Array(4).fill(""))
  );
  const [powerNum1, setPowerNum1] = useState(2);
  const [multiplyNum1, setMultiplyNum1] = useState(3);

  const [rows2, setRows2] = useState(4);
  const [columns2, setColumns2] = useState(4);
  const [matrix2, setMatrix2] = useState(
    Array.from({ length: 4 }, () => Array(4).fill(""))
  );
  const [powerNum2, setPowerNum2] = useState(2);
  const [multiplyNum2, setMultiplyNum2] = useState(3);
  const [operations, setOperations] = useState("A + B");

  const [resultA, setResultA] = useState(null);
  const [resultB, setResultB] = useState(null);
  const [resultAB, setResultAB] = useState(null);

  const handleDimensionChange = (matrix, type, value) => {
    const numValue = parseInt(value);
    if (matrix === "matrix1") {
      if (type === "rows") {
        setRows1(numValue);
        setMatrix1((prev) =>
          Array.from({ length: numValue }, (_, i) =>
            i < prev.length ? prev[i] : Array(columns1).fill("")
          )
        );
      } else if (type === "columns") {
        setColumns1(numValue);
        setMatrix1((prev) =>
          prev.map((row) =>
            Array.from({ length: numValue }, (_, j) =>
              j < row.length ? row[j] : ""
            )
          )
        );
      }
    } else if (matrix === "matrix2") {
      if (type === "rows") {
        setRows2(numValue);
        setMatrix2((prev) =>
          Array.from({ length: numValue }, (_, i) =>
            i < prev.length ? prev[i] : Array(columns2).fill("")
          )
        );
      } else if (type === "columns") {
        setColumns2(numValue);
        setMatrix2((prev) =>
          prev.map((row) =>
            Array.from({ length: numValue }, (_, j) =>
              j < row.length ? row[j] : ""
            )
          )
        );
      }
    }
  };

  const handleCellChange = (matrix, rowIndex, colIndex, value) => {
    if (matrix === "matrix1") {
      setMatrix1((prev) =>
        prev.map((row, i) =>
          row.map((cell, j) =>
            i === rowIndex && j === colIndex ? value : cell
          )
        )
      );
    } else if (matrix === "matrix2") {
      setMatrix2((prev) =>
        prev.map((row, i) =>
          row.map((cell, j) =>
            i === rowIndex && j === colIndex ? value : cell
          )
        )
      );
    }
  };

  const generateRandomMatrix = () => {
    const randomMatrix = (rows, columns) =>
      Array.from({ length: rows }, () =>
        Array.from({ length: columns }, () => Math.floor(Math.random() * 10))
      );

    setMatrix1(randomMatrix(rows1, columns1));
    setMatrix2(randomMatrix(rows2, columns2));
  };

  const clearMatrixValues = () => {
    const randomMatrix = (rows, columns) =>
      Array.from({ length: rows }, () =>
        Array.from({ length: columns }, () => "")
      );

    setMatrix1(randomMatrix(rows1, columns1));
    setMatrix2(randomMatrix(rows2, columns2));
  };

  const performOperation = () => {
    try {
      const matrixA = new mlMatrix.Matrix(
        matrix1.map((row) => row.map((val) => parseFloat(val || 0)))
      );
      const matrixB = new mlMatrix.Matrix(
        matrix2.map((row) => row.map((val) => parseFloat(val || 0)))
      );

      let matrixA1;
      let matrixB2;
      let resultA = null;
      let resultB = null;
      let resultAB = null;

      const matrixPower = (matrix, power) => {
        if (power < 0) {
          throw new Error("Matrix power must be a non-negative integer.");
        }
        if (!matrix.isSquare()) {
          throw new Error("Matrix must be square to compute power.");
        }

        let result = mlMatrix.Matrix.eye(matrix.rows);
        let base = matrix.clone();

        while (power > 0) {
          if (power % 2 === 1) {
            result = result.mmul(base);
          }
          base = base.mmul(base);
          power = Math.floor(power / 2);
        }

        return result;
      };

      switch (operations) {
        case "Transpose":
          resultA = matrixA.transpose().to2DArray();
          resultB = matrixB.transpose().to2DArray();
          break;
        case "power of matrix":
          resultA = matrixPower(matrixA, powerNum1).to2DArray();
          resultB = matrixPower(matrixB, powerNum2).to2DArray();

          break;
        case "Determinant":
          matrixA1 = determinant(matrixA);
          matrixB2 = determinant(matrixB);

          resultA = Math.round(parseFloat(matrixA1) * 100) / 100;
          resultB = Math.round(parseFloat(matrixB2) * 100) / 100;
          break;
        case "Inverse":
          matrixA1 = inverse(matrixA);
          matrixB2 = inverse(matrixB);

          resultA = matrixA1.data.map((row) =>
            Object.values(row)
              .filter((value) => value !== "")
              .map((value) => Math.round(parseFloat(value) * 100000) / 100000)
          );

          resultB = matrixB2.data.map((row) =>
            Object.values(row)
              .filter((value) => value !== "")
              .map((value) => Math.round(parseFloat(value) * 100000) / 100000)
          );

          break;
        case "multiply of matrix":
          resultA = matrixA.multiply(parseFloat(multiplyNum1)).to2DArray();
          resultB = matrixB.multiply(parseFloat(multiplyNum2)).to2DArray();
          break;
        case "A + B":
          resultAB = matrixA.add(matrixB).to2DArray();
          break;
        case "A - B":
          resultAB = matrixA.sub(matrixB).to2DArray();
          break;
        case "AB":
          resultAB = matrixA.mmul(matrixB).to2DArray();
          break;
        default:
          resultAB = [["Invalid operation"]];
      }

      setResultA(resultA);
      setResultB(resultB);
      setResultAB(resultAB);
    } catch (error) {
      console.error("Error performing operation:", error);
      if (
        operations === "Transpose" ||
        operations === "power of matrix" ||
        operations === "Determinant" ||
        operations === "Inverse" ||
        operations === "multiply of matrix"
      ) {
        setResultA([["Error in computation"]]);
        setResultB([["Error in computation"]]);
      } else {
        setResultAB([["Error in computation"]]);
      }
    }
  };

  useEffect(() => {
    performOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matrix1,
    matrix2,
    rows1,
    columns1,
    rows2,
    columns2,
    operations,
    powerNum1,
    multiplyNum1,
    powerNum2,
    multiplyNum2,
  ]);

  function MatrixToLatex(matrix) {
    if (!matrix || !Array.isArray(matrix)) return "";

    const latex = matrix.map((row) => row.join(" & ")).join(" \\\\ ");

    return `\\begin{pmatrix} ${latex} \\end{pmatrix}`;
  }

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <Typography className="common-sub-heading-calc">
                Matrix A:
              </Typography>
              <InputComponent
                isInput={true}
                label="Rows:"
                value={rows1}
                min={1}
                max={10}
                setState={(value) =>
                  handleDimensionChange("matrix1", "rows", value)
                }
              />
              <InputComponent
                isInput={true}
                label="Columns:"
                value={columns1}
                min={1}
                max={10}
                setState={(value) =>
                  handleDimensionChange("matrix1", "columns", value)
                }
              />
              <Grid className="matrix-inputs-parent">
                {matrix1.map((row, rowIndex) => (
                  <Grid key={rowIndex} style={{ display: "flex" }}>
                    {row.map((cell, colIndex) => (
                      <input
                        key={colIndex}
                        type="number"
                        value={cell}
                        onChange={(e) =>
                          handleCellChange(
                            "matrix1",
                            rowIndex,
                            colIndex,
                            e.target.value
                          )
                        }
                        className="matrix-cells"
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
              {operations === "power of matrix" && (
                <InputComponent
                  isInput={true}
                  label="Power value of matrix A"
                  value={powerNum1}
                  min={1}
                  max={10}
                  setState={setPowerNum1}
                />
              )}
              {operations === "multiply of matrix" && (
                <InputComponent
                  isInput={true}
                  label="multiply value of matrix A"
                  value={multiplyNum1}
                  min={1}
                  max={1000}
                  setState={setMultiplyNum1}
                />
              )}
            </Grid>

            <Grid>
              <Typography className="common-sub-heading-calc">
                Matrix B:
              </Typography>
              <InputComponent
                isInput={true}
                label="Rows:"
                value={rows2}
                min={1}
                max={10}
                setState={(value) =>
                  handleDimensionChange("matrix2", "rows", value)
                }
              />
              <InputComponent
                isInput={true}
                label="Columns:"
                value={columns2}
                min={1}
                max={10}
                setState={(value) =>
                  handleDimensionChange("matrix2", "columns", value)
                }
              />
              <Grid className="matrix-inputs-parent">
                {matrix2.map((row, rowIndex) => (
                  <Grid key={rowIndex} style={{ display: "flex" }}>
                    {row.map((cell, colIndex) => (
                      <input
                        key={colIndex}
                        type="number"
                        value={cell}
                        onChange={(e) =>
                          handleCellChange(
                            "matrix2",
                            rowIndex,
                            colIndex,
                            e.target.value
                          )
                        }
                        className="matrix-cells"
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
              {operations === "power of matrix" && (
                <InputComponent
                  isInput={true}
                  label="Power value of matrix B"
                  value={powerNum2}
                  min={1}
                  max={10}
                  setState={setPowerNum2}
                />
              )}
              {operations === "multiply of matrix" && (
                <InputComponent
                  isInput={true}
                  label="multiply value of matrix B"
                  value={multiplyNum2}
                  min={1}
                  max={1000}
                  setState={setMultiplyNum2}
                />
              )}
            </Grid>
            <Grid>
              <SelectBoxComponent
                label={"Operations"}
                value={operations}
                setValue={setOperations}
                data={[
                  "Transpose",
                  "power of matrix",
                  "Determinant",
                  "Inverse",
                  "multiply of matrix",
                  "A + B",
                  "A - B",
                  "AB",
                ]}
                placeholder="Select a operation"
              />
            </Grid>
            <Grid className="random-clear-btn">
              <Button onClick={generateRandomMatrix} variant="contained">
                Random
              </Button>
              <Button onClick={clearMatrixValues} variant="contained">
                Clear All
              </Button>
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid sx={{ width: "100%", overflowX: "auto" }}>
              {operations === "Determinant" ? (
                <>
                  {resultA && (
                    <Typography className="matrix-result">
                      <strong className="matrix-label">
                        Determinant of A =&nbsp;
                      </strong>
                      {JSON.stringify(resultA)}
                    </Typography>
                  )}
                  {resultB && (
                    <Typography className="matrix-result">
                      <strong className="matrix-label">
                        Determinant of B =&nbsp;
                      </strong>
                      {JSON.stringify(resultB)}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {resultA && (
                    <Typography className="matrix-result">
                      <strong className="matrix-label">
                        {operations === "Transpose" ? (
                          "Aᵀ ="
                        ) : operations === "power of matrix" ? (
                          <>
                            A<sup>{powerNum1}</sup> =
                          </>
                        ) : operations === "Inverse" ? (
                          <>
                            A<sup>-1</sup> =
                          </>
                        ) : (
                          `A × ${multiplyNum1} =`
                        )}
                      </strong>
                      <BlockMath math={MatrixToLatex(resultA)} />
                    </Typography>
                  )}
                  {resultB && (
                    <Typography className="matrix-result">
                      <strong className="matrix-label">
                        {operations === "Transpose" ? (
                          "Bᵀ ="
                        ) : operations === "power of matrix" ? (
                          <>
                            B<sup>{powerNum2}</sup> =
                          </>
                        ) : operations === "Inverse" ? (
                          <>
                            B<sup>-1</sup> =
                          </>
                        ) : (
                          `B × ${multiplyNum2} =`
                        )}
                      </strong>
                      <BlockMath math={MatrixToLatex(resultB)} />
                    </Typography>
                  )}
                </>
              )}
              {resultAB && (
                <Typography className="matrix-result">
                  <strong className="matrix-label">
                    {operations === "A + B"
                      ? "A + B ="
                      : operations === "A - B"
                      ? "A - B ="
                      : "AB ="}
                  </strong>
                  <BlockMath math={MatrixToLatex(resultAB)} />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default MatrixCalc;
