import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import BigNumber from "bignumber.js";

const DUPLICATIONDATA = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const SORTRESULTDATA = [
  { label: "Ascend", value: "ascend" },
  { label: "Descend", value: "descend" },
  { label: "No", value: "no" },
];

const RESULTTYPEDATA = [
  { label: "Integer", value: "integer" },
  { label: "Decimal", value: "decimal" },
];

export default function RandomNumberGeneratorCalc() {
  const [lowerLimit, setLowerLimit] = useState(1);
  const [upperLimit, setUpperLimit] = useState(100);
  const [randomNumber, setRandomNumber] = useState();

  const [lowerLimit1, setLowerLimit1] = useState(1);
  const [upperLimit1, setUpperLimit1] = useState(100);
  const [generateNumber, setGenerateNumber] = useState(1);
  const [duplication, setDuplication] = useState({ name: "Yes", value: "yes" });
  const [sortResult, setSortResult] = useState({ name: "No", value: "no" });
  const [resultType, setResultType] = useState({
    name: "Decimal",
    value: "decimal",
  });
  const [precisionDigits, setPrecisionDigits] = useState(5);

  const [randomNumber1, setRandomNumber1] = useState([]);

  useEffect(() => {
    if (lowerLimit < upperLimit - 1) {
        randomNumberGenerate();
    } else {
        setRandomNumber("Invalid range");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [lowerLimit, upperLimit]);

const randomNumberGenerate = () => {
  if (lowerLimit < upperLimit - 1) {
      const randomNum = Math.floor(Math.random() * (+upperLimit - +lowerLimit - 1)) + +lowerLimit + 1;
      setRandomNumber(+randomNum);
  } else {
      setRandomNumber("Invalid range");
  }
};

  useEffect(() => {
    generateRandomNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lowerLimit1,
    upperLimit1,
    generateNumber,
    duplication,
    sortResult,
    resultType,
    precisionDigits,
  ]);

  const generateRandomNumbers = () => {
    if (lowerLimit1 < upperLimit1) {
      let numbers = [];
      const lower = new BigNumber(parseFloat(+lowerLimit1) || 0);
      const upper = new BigNumber(parseFloat(+upperLimit1) || 0);
      let precision = parseInt(+precisionDigits, 10);

      if (isNaN(precision) || precision < 0) {
        precision = 0;
      }
      const generateNumberWithPrecision = () => {
        const range = upper.minus(+lower);
        const randomNumber = new BigNumber(+Math.random())
          .times(+range)
          .plus(+lower);
        let result =
          resultType.value === "decimal"
            ? +randomNumber.toFixed(+precision)
            : +randomNumber.toFixed(0);

        if (resultType.value === "decimal") {
          result = BigNumber(+result).toFixed(+precision);
        }

        return result;
      };

      while (numbers.length < generateNumber) {
        const num = generateNumberWithPrecision();
        if (duplication.value === "no" && numbers.includes(+num)) {
          continue;
        }
        numbers.push(num);
      }

      if (sortResult.value === "ascend") {
        numbers.sort((a, b) => BigNumber(a).minus(BigNumber(b)).toNumber());
      } else if (sortResult.value === "descend") {
        numbers.sort((a, b) => BigNumber(b).minus(BigNumber(a)).toNumber());
      }

      setRandomNumber1(numbers.join(", <br/>"));
    } else {
      setRandomNumber1("Invalid range");
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" }, gap: 3 }}>
          <Grid item xs={12} md={5.8} className="calculation-part ">
            <Typography className="common-sub-heading-calc">
              Random Number Generator
            </Typography>

            <Grid>
              <InputComponent
                label="Lower Limit"
                isInput={true}
                value={lowerLimit}
                min={1}
                max={10000000000}
                setState={setLowerLimit}
              />
              <InputComponent
                label="Upper Limit"
                isInput={true}
                value={upperLimit}
                min={1}
                max={10000000000}
                setState={setUpperLimit}
              />
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid className="result-common-div">
              <InputComponent
                label="Random Number"
                isInput={false}
                value={randomNumber}
                date={true}
              />
            </Grid>
            <Grid
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => randomNumberGenerate()}
                variant="contained"
              >
                Regenerate
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} md={5.8} className="calculation-part ">
            <Typography className="common-sub-heading-calc">
              Comprehensive Version
            </Typography>

            <Grid>
              <InputComponent
                label="Lower Limit"
                isInput={true}
                value={lowerLimit1}
                min={1}
                max={10000000000}
                setState={setLowerLimit1}
              />
              <InputComponent
                label="Upper Limit"
                isInput={true}
                value={upperLimit1}
                min={1}
                max={10000000000}
                setState={setUpperLimit1}
              />
              <InputComponent
                label="Generate Number"
                isInput={true}
                value={generateNumber}
                min={1}
                max={50}
                setState={setGenerateNumber}
              />
              {generateNumber > 1 && (
                <>
                  <RadioButtonComponent
                    label={"Allow duplication in results?"}
                    data={DUPLICATIONDATA}
                    defaultValue={"yes"}
                    valueSetter={setDuplication}
                    row={false}
                  />
                  <RadioButtonComponent
                    label={"Sort the results?"}
                    data={SORTRESULTDATA}
                    defaultValue={"no"}
                    valueSetter={setSortResult}
                    row={false}
                  />
                </>
              )}
              <RadioButtonComponent
                label={"Type of result to generate?"}
                data={RESULTTYPEDATA}
                defaultValue={"decimal"}
                valueSetter={setResultType}
                row={false}
              />
              {resultType.value === "decimal" && (
                <InputComponent
                  label="Precision Digits"
                  isInput={true}
                  value={precisionDigits > 17 ? 17 : precisionDigits}
                  min={1}
                  max={17}
                  setState={setPrecisionDigits}
                />
              )}
            </Grid>

            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Random Number"
                isInput={false}
                value={randomNumber1}
                date={true}
              />
            </Grid>
            <Grid
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => generateRandomNumbers()}
                variant="contained"
              >
                Regenerate
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}
