import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

function APYCalc() {
  const [interestApr, setInterestApr] = useState(5);
  const [compoundingFrequency, setCompoundingFrequency] = useState("monthly");
  const [term, setTerm] = useState(5);
  const [selectedUnit, setSelectedUnit] = useState("Years");
  const [initialValue, setInitialValue] = useState(100000);

  const [apy, setApy] = useState(0);
  const [finalValue, setFinalValue] = useState(0);

  const maxValues = {
    Days: 365000,
    Weeks: 10000,
    Months: 2400,
    Years: 200,
  };

  useEffect(() => {
    const rate = interestApr / 100;
    let compoundingPeriods;

    let termInYears = term;
    switch (selectedUnit) {
      case "Days":
        termInYears = term / 365;
        break;
      case "Weeks":
        termInYears = term / 52;
        break;
      case "Fortnights":
        termInYears = term / 26;
        break;
      case "Months":
        termInYears = term / 12;
        break;
      // No conversion needed for "Years"
      case "Years":
      default:
        break;
    }

    switch (compoundingFrequency) {
      case "yearly":
        compoundingPeriods = 1;
        break;
      case "half-yearly":
        compoundingPeriods = 2;
        break;
      case "quarterly":
        compoundingPeriods = 4;
        break;
      case "monthly":
        compoundingPeriods = 12;
        break;
      case "weekly":
        compoundingPeriods = 52;
        break;
      case "daily":
        compoundingPeriods = 365;
        break;
      default:
        compoundingPeriods = 12;
    }

    const calculatedAPY =
      Math.pow(1 + rate / compoundingPeriods, compoundingPeriods) - 1;

    const finalAmount =
      initialValue *
      Math.pow(1 + rate / compoundingPeriods, compoundingPeriods * termInYears);

    if (selectedUnit && term) {
      const maxValue = maxValues[selectedUnit];
      if (Number(term) > maxValue) {
        setTerm(maxValue);
      }
    }

    setApy((calculatedAPY * 100).toFixed(3));
    setFinalValue(finalAmount.toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestApr, compoundingFrequency, term, selectedUnit, initialValue]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (/^\d*(\.\d*)?$/.test(newValue)) {
      setTerm(newValue);
    }
  };

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part ">
            <Grid>
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Interest(APR)"
                value={interestApr}
                min={1}
                max={1000}
                setState={setInterestApr}
              />
              <SelectBoxComponent
                label={"Compound frequency"}
                value={compoundingFrequency}
                setValue={setCompoundingFrequency}
                data={[
                  "yearly",
                  "half-yearly",
                  "quarterly",
                  "monthly",
                  "weekly",
                  "daily",
                ]}
                placeholder="Select a frequency"
              />
              <Grid className="concrete-calc-input pace">
                <Typography className="input-label">Term</Typography>
                <Grid sx={{ display: "flex", marginTop: "2px" }}>
                  <input
                    type="text"
                    value={term}
                    onChange={handleInputChange}
                    className="input-field"
                  />

                  <Grid className="select-box-wrap">
                    <SelectBoxComponent
                      value={selectedUnit}
                      setValue={setSelectedUnit}
                      data={["Days", "Weeks", "Months", "Years"]}
                      placeholder="Select time unit"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <InputComponent
                isInput={true}
                flag="rupee"
                label="Initial balance"
                min={0}
                max={10000000000}
                setState={setInitialValue}
                value={initialValue}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Annual percentage yield"
                isInput={false}
                flag="percentage"
                value={apy}
              />
              <InputComponent
                label="Final balance"
                isInput={false}
                value={finalValue}
                flag="rupee"
              />
            </Grid>
          </Grid>
        </Grid>

        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default APYCalc;
