import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import "../GPACalc/Table.scss";

export default function DenseTable({
  numOfRows,
  headerData,
  date,
  from,
  to,
  breaks,
}) {
  const rowHeaderCellStyling = {
    align: "left",
    backgroundColor: "#295fa6",
    fontWeight: "bold",
    color: "white",
    paddingRight: "5px",
  };

  return (
    <TableContainer className="tableBorder" sx={{}} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ border: "2px solid #295fa6" }}>
            {headerData?.map((item, index) => (
              <TableCell
                align="center"
                sx={{ ...rowHeaderCellStyling }}
                key={index}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(numOfRows)].map((value, i) => (
            <TableRow key={i}>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`dt${i}`}
                  name={`dt${i}`}
                  size="small"
                  variant="outlined"
                  value={date.data[`dt${i}`]}
                  onChange={(event) => {
                    date.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`fr${i}`}
                  name={`fr${i}`}
                  size="small"
                  variant="outlined"
                  value={from.data[`fr${i}`]}
                  onChange={(event) => {
                    from.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`to${i}`}
                  name={`to${i}`}
                  size="small"
                  variant="outlined"
                  value={to.data[`to${i}`]}
                  onChange={(event) => {
                    to.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id={`br${i}`}
                  name={`br${i}`}
                  size="small"
                  variant="outlined"
                  value={breaks.data[`br${i}`]}
                  onChange={(event) => {
                    breaks.setter((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
