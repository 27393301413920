import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function FinalGradeCalc() {
  const [currentGradeInput, setCurrentGradeInput] = useState(88);
  const [wantGradeInput, setWantGradeInput] = useState(85);
  const [finalWorth, setFinalWorth] = useState(40);
  const [requiredGrade, setRequiredGrade] = useState(null);

  useEffect(() => {
    if (currentGradeInput && wantGradeInput && finalWorth) {
      const required = (
        (Number(wantGradeInput) -
          (1 - Number(finalWorth) / 100) * Number(currentGradeInput)) /
        (Number(finalWorth) / 100)
      ).toFixed(2);
      setRequiredGrade(required < 0 ? 0 : required);
    } else {
      setRequiredGrade(null);
    }
  }, [currentGradeInput, wantGradeInput, finalWorth]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                label="Your current grade:"
                value={currentGradeInput}
                setState={setCurrentGradeInput}
                max={1000}
              />
              <InputComponent
                isInput={true}
                label="The grade you want:"
                max={1000}
                setState={setWantGradeInput}
                value={wantGradeInput}
              />
              <InputComponent
                isInput={true}
                flag="percentage"
                label="Your final is worth:"
                setState={setFinalWorth}
                value={finalWorth}
                min={0}
                max={100}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <Typography sx={{ ml: 1, mt: 3, fontSize: "1.3rem" }}>
                You will need a grade of{" "}
                <b>
                  <span style={{ color: "green" }}>{requiredGrade}</span>
                </b>{" "}
                or higher on the final.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default FinalGradeCalc;
