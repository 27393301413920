import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import RadioButtonComponent from "../../Common/RadioButton/RadioButton";
import { GENDERS } from "../../../Utils/calorieHelper";
import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";
import HeightFromParents from "./HeightFromParents";

function HeightCalc() {
  const [units, setUnits] = useState("Metric Units");
  const [childAge, setChildAge] = useState(5.2);
  const [gender, setGender] = useState({ name: "Male", value: "male" });
  const [childHeight, setChildHeight] = useState(112);
  const [childWeight, setChildWeight] = useState(18);
  const [motherHeight, setMotherHeight] = useState(164);
  const [fatherHeight, setFatherHeight] = useState(178);

  const [childHeightInFeet, setChildHeightInFeet] = useState(3);
  const [childHeightInInches, setChildHeightInInches] = useState(8);
  const [childWeightInPound, setChildWeightInPound] = useState(40);
  const [motherHeightInFeet, setMotherHeightInFeet] = useState(5);
  const [motherHeightInInches, setMotherHeightInInches] = useState(5);
  const [fatherHeightInFeet, setFatherHeightInFeet] = useState(5);
  const [fatherHeightInInches, setFatherHeightInInches] = useState(10);

  const [adultHeight, setAdultHeight] = useState(0);
  const [adultHeightInFeet, setAdultHeightInFeet] = useState(0);
  const [adultHeightInInches, setAdultHeightInInches] = useState(0);

  useEffect(() => {
    const calculateAdultHeight = () => {
      const calculateMidParentHeight = () => {
        if (units === "Metric Units") {
          return (parseFloat(motherHeight) + parseFloat(fatherHeight)) / 2 || 0;
        } else if (units === "US Units") {
          const motherHeightInches =
            motherHeightInFeet * 12 + parseFloat(motherHeightInInches) || 0;
          const fatherHeightInches =
            fatherHeightInFeet * 12 + parseFloat(fatherHeightInInches) || 0;
          return (motherHeightInches + fatherHeightInches) / 2 || 0;
        }
      };

      const midParentHeight = calculateMidParentHeight();

      if (units === "Metric Units") {
        const genderFactor = gender.value === "male" ? 6.5 : -6.5;
        const expectedHeight = midParentHeight + genderFactor;
        setAdultHeight(Math.round(expectedHeight));

        const totalInches = expectedHeight / 2.54;
        const feet = Math.floor(totalInches / 12);
        const inches = Number((totalInches % 12).toFixed(1));
        setAdultHeightInFeet(feet);
        setAdultHeightInInches(inches);
      } else if (units === "US Units") {
        const genderFactor = gender.value === "male" ? 2.5 : -2.5;
        const expectedHeightInches = midParentHeight + genderFactor;
        const feet = Math.floor(expectedHeightInches / 12);
        const inches = Number((expectedHeightInches % 12).toFixed(1));
        setAdultHeightInFeet(feet);
        setAdultHeightInInches(inches);

        const heightInCm = Math.round(expectedHeightInches * 2.54);
        setAdultHeight(heightInCm);
      }
    };

    calculateAdultHeight();
  }, [
    units,
    gender,
    motherHeight,
    fatherHeight,
    motherHeightInFeet,
    motherHeightInInches,
    fatherHeightInFeet,
    fatherHeightInInches,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />

        <Grid container gap={2.5} sx={{ mt: 2 }}>
          <Grid item xs={12} md={5.9} className="calculation-part ">
            <Grid sx={{ margin: "20px 8px" }}>
              <TabbingComponent
                data={["Metric Units", "US Units"]}
                value={units}
                setValue={setUnits}
              />
            </Grid>
            <Grid>
              <InputComponent
                isInput={true}
                label="Child's Age"
                value={childAge}
                min={0}
                max={18}
                setState={setChildAge}
              />
              <RadioButtonComponent
                label={"Child's Gender"}
                data={GENDERS}
                defaultValue={"male"}
                valueSetter={setGender}
                row={false}
              />
              {units === "Metric Units" ? (
                <>
                  <InputComponent
                    isInput={true}
                    label="Child's Height"
                    min={0}
                    max={200}
                    flag="centimeter"
                    setState={setChildHeight}
                    value={childHeight}
                  />
                  <InputComponent
                    isInput={true}
                    label="Child's Weight"
                    setState={setChildWeight}
                    value={childWeight}
                    min={0}
                    max={250}
                    flag="kilogram"
                  />
                  <InputComponent
                    isInput={true}
                    label="Mother's Height"
                    min={0}
                    max={250}
                    flag="centimeter"
                    setState={setMotherHeight}
                    value={motherHeight}
                  />
                  <InputComponent
                    isInput={true}
                    label="Father's Height"
                    min={0}
                    max={250}
                    flag="centimeter"
                    setState={setFatherHeight}
                    value={fatherHeight}
                  />
                </>
              ) : (
                <>
                  <InputComponent
                    isInput={true}
                    label="Child's Height (feet)"
                    min={0}
                    max={8}
                    setState={setChildHeightInFeet}
                    value={childHeightInFeet}
                    flag="feet"
                  />
                  <InputComponent
                    isInput={true}
                    label="Child's Height (inches)"
                    min={0}
                    max={11}
                    setState={setChildHeightInInches}
                    value={childHeightInInches}
                    flag="inches"
                  />
                  <InputComponent
                    isInput={true}
                    label="Child's Weight (pounds)"
                    setState={setChildWeightInPound}
                    value={childWeightInPound}
                    min={0}
                    max={250}
                    flag="lb"
                  />
                  <InputComponent
                    isInput={true}
                    label="Mother's Height (feet)"
                    min={0}
                    max={8}
                    flag="feet"
                    setState={setMotherHeightInFeet}
                    value={motherHeightInFeet}
                  />
                  <InputComponent
                    isInput={true}
                    label="Mother's Height (inches)"
                    min={0}
                    max={11}
                    flag="inches"
                    setState={setMotherHeightInInches}
                    value={motherHeightInInches}
                  />
                  <InputComponent
                    isInput={true}
                    label="Father's Height (feet)"
                    min={0}
                    max={8}
                    flag="feet"
                    setState={setFatherHeightInFeet}
                    value={fatherHeightInFeet}
                  />
                  <InputComponent
                    isInput={true}
                    label="Father's Height (inches)"
                    min={0}
                    max={11}
                    flag="inches"
                    setState={setFatherHeightInInches}
                    value={fatherHeightInInches}
                  />
                </>
              )}
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              {units === "Metric Units" ? (
                <InputComponent
                  label="Expected adult height"
                  isInput={false}
                  date={true}
                  value={`${adultHeight} cm`}
                />
              ) : (
                <InputComponent
                  label="Expected adult height"
                  isInput={false}
                  date={true}
                  value={`${adultHeightInFeet} feet ${adultHeightInInches} inches`}
                />
              )}
            </Grid>
          </Grid>
          <HeightFromParents />
        </Grid>

        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default HeightCalc;
