import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function QuadraticFormulaCalc() {
  const [valueOfA, setValueOfA] = useState("1");
  const [valueOfB, setValueOfB] = useState("1");
  const [valueOfC, setValueOfC] = useState("1/4");
  const [equation, setEquation] = useState("0");
  const [valueOfX, setValueOfX] = useState("0");

  useEffect(() => {
    function parseFraction(input) {
      if (!input || input.trim() === "") {
        return 0;
      }

      try {
        if (input.includes("/")) {
          const [numerator, denominator] = input.split("/").map((str) => {
            const num = str.trim() === "" ? 0 : Number(str);
            return isNaN(num) ? 0 : num;
          });
          return denominator === 0 ? 0 : numerator / denominator;
        }
        const num = Number(input);
        return isNaN(num) ? 0 : num;
      } catch {
        return 0;
      }
    }

    const a = parseFraction(valueOfA);
    const b = parseFraction(valueOfB);
    const c = parseFraction(valueOfC);

    const formatNumber = (num) => {
      if (num === 0) return "0";
      if (num === 1) return "";
      if (num === -1) return "-";
      return num.toString();
    };

    if (a === 0 && b === 0 && c === 0) {
      setEquation("0 = 0");
      setValueOfX("All values of x are solutions");
      return;
    } else if (a === 0 && b === 0) {
      setEquation(`${c} = 0`);
      setValueOfX("No solution");
      return;
    } else if (a === 0) {
      const root = -c / b;
      setEquation(
        `${b !== 0 ? formatNumber(b) + "x" : ""} ${
          c >= 0 ? "+ " + c : "- " + Math.abs(c)
        } = 0`
      );
      setValueOfX(b === 0 ? "No solution" : `x = ${root.toFixed(3)}`);
      return;
    }

    const bTerm =
      b === 0 ? "" : `${b > 0 ? " + " : " - "}${formatNumber(Math.abs(b))}x`;
    const cTerm = c === 0 ? "" : `${c > 0 ? " + " : " - "}${Math.abs(c)}`;
    setEquation(`${formatNumber(a)}x² ${bTerm}${cTerm} = 0`);

    const discriminant = b * b - 4 * a * c;

    if (discriminant > 0) {
      const root1 = (-b + Math.sqrt(discriminant)) / (2 * a);
      const root2 = (-b - Math.sqrt(discriminant)) / (2 * a);
      setValueOfX(`x = ${root1.toFixed(3)}, x = ${root2.toFixed(3)}`);
    } else if (discriminant === 0) {
      const root = -b / (2 * a);
      setValueOfX(`x = ${root.toFixed(3)}`);
    } else {
      const realPart = (-b / (2 * a)).toFixed(3);
      const imaginaryPart = (Math.sqrt(-discriminant) / (2 * a)).toFixed(3);
      setValueOfX(`x = ${realPart} ± ${imaginaryPart}i`);
    }
  }, [valueOfA, valueOfB, valueOfC]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Typography className="common-sub-heading-desc">
              The calculator below solves the quadratic equation of ax² + bx + c
              = 0 .
            </Typography>
            <Grid>
              <InputComponent
                type="text"
                isInput={true}
                label="constant value of a"
                value={valueOfA}
                setState={setValueOfA}
                min={0}
                max={100}
              />
              <InputComponent
                type="text"
                isInput={true}
                label="constant value of b"
                min={0}
                max={100}
                setState={setValueOfB}
                value={valueOfB}
              />
              <InputComponent
                type="text"
                isInput={true}
                label="constant value of c"
                setState={setValueOfC}
                value={valueOfC}
                min={0}
                max={100}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Equation"
                isInput={false}
                value={equation}
                date={true}
              />
              <InputComponent
                label="Value of x"
                isInput={false}
                value={valueOfX}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default QuadraticFormulaCalc;
