import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function SlopeCalc() {
  const [valueOfX1, setValueOfX1] = useState(1);
  const [valueOfY1, setValueOfY1] = useState(1);
  const [valueOfX2, setValueOfX2] = useState(2);
  const [valueOfY2, setValueOfY2] = useState(2);
  const [slope, setSlope] = useState(0);
  const [angle, setAngle] = useState(0);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const deltaX = +valueOfX2 - +valueOfX1;
    const deltaY = +valueOfY2 - +valueOfY1;

    const slopeValue = +deltaY / +deltaX;
    const angleInRadians = Math.atan(+slopeValue);
    const angleInDegrees = +angleInRadians * (180 / Math.PI);
    const distanceValue = Math.sqrt(deltaX ** 2 + deltaY ** 2);

    setSlope(slopeValue.toFixed(2));
    setAngle(angleInDegrees.toFixed(2));
    setDistance(distanceValue.toFixed(5));
  }, [valueOfX1, valueOfX2, valueOfY1, valueOfY2]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                label={
                  <>
                    Value of X<sub>1</sub>
                  </>
                }
                min={0}
                max={10000000000}
                setState={setValueOfX1}
                value={valueOfX1}
              />
              <InputComponent
                isInput={true}
                label={
                  <>
                    Value of Y<sub>1</sub>
                  </>
                }
                min={0}
                max={10000000000}
                setState={setValueOfY1}
                value={valueOfY1}
              />
              <InputComponent
                isInput={true}
                label={
                  <>
                    Value of X<sub>2</sub>
                  </>
                }
                min={0}
                max={10000000000}
                setState={setValueOfX2}
                value={valueOfX2}
              />
              <InputComponent
                isInput={true}
                label={
                  <>
                    Value of Y<sub>2</sub>
                  </>
                }
                setState={setValueOfY2}
                value={valueOfY2}
                min={0}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Slope (m)"
                isInput={false}
                value={slope || 0}
                date={true}
              />
              <InputComponent
                label="Angle (θ)"
                isInput={false}
                value={`${angle}°`}
                date={true}
              />
              <InputComponent
                label="Distance (d)"
                isInput={false}
                value={distance}
                date={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default SlopeCalc;
