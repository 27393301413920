import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import plusIcon from "../../../Assets/Icon/plusIcon.png";
import PieChartComponent from "../../Common/PieChart/PieChart";
import TableComponent from "../../Common/TableComponent/TableComponent";
import BarChartComponent from "../../Common/BarChart/BarCahrt";

function Retirement401KCalc() {
  const [currentAge, setCurrentAge] = useState(30);
  const [annualIncome, setAnnualIncome] = useState(75000);
  const [startingBalance, setStartingBalance] = useState(35000);
  const [monthlyContribution, setMonthlyContribution] = useState(10);
  const [employerMatch, setEmployerMatch] = useState(50);
  const [employerMatchLimit, setEmployerMatchLimit] = useState(3);
  const [retirementAge, setRetirementAge] = useState(65);
  const [estimatedReturn, setEstimatedReturn] = useState(6);
  const [salaryIncrease, setSalaryIncrease] = useState(3);
  const [contributions, setContributions] = useState(0);
  const [employerMatchResult, setEmployerMatchResult] = useState(0);
  const [employeeContributions, setEmployeeContributions] = useState(0);
  const [investmentReturns, setInvestmentReturns] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [tableView, setTableView] = useState(false);
  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    let balance = +startingBalance;
    let salary = +annualIncome;
    let records = [];
    let barRecords = [];
    let totalEmployeeContributions = 0;
    let totalEmployerMatch = 0;
    let totalInvestmentReturns = 0;
    let cumulativeEmployeeContributions = 0;
    let cumulativeEmployerMatch = 0;
    let cumulativeInvestmentReturns = 0;

    if (retirementAge > currentAge) {
      for (let age = +currentAge + 1; age <= +retirementAge; age++) {
        let employeeContribution = (+salary * +monthlyContribution) / 100;
        totalEmployeeContributions += +employeeContribution;

        let employerMatchLimitAmount = (+salary * +employerMatchLimit) / 100;
        let employerMatchAmount =
          (+employerMatchLimitAmount * +employerMatch) / 100;
        totalEmployerMatch += +employerMatchAmount;

        let totalContribution = +employeeContribution + +employerMatchAmount;

        let investmentReturn =
          (+balance + +totalContribution / 2) * (+estimatedReturn / 100);
        totalInvestmentReturns += +investmentReturn;

        balance = +balance + +totalContribution + +investmentReturn;

        records.push({
          age: +age,
          salary: +salary.toFixed(2),
          employeeContribution: +employeeContribution.toFixed(2),
          employerMatch: +employerMatchAmount.toFixed(2),
          totalContribution: +totalContribution.toFixed(2),
          investmentReturn: +investmentReturn.toFixed(2),
          balance: +balance.toFixed(2),
        });

        salary += +salary * (+salaryIncrease / 100);

        cumulativeEmployeeContributions += parseFloat(employeeContribution);
        cumulativeEmployerMatch += parseFloat(employerMatchAmount);
        cumulativeInvestmentReturns += parseFloat(investmentReturn);

        barRecords.push({
          age: +age,
          employeeContribution: +cumulativeEmployeeContributions.toFixed(0),
          employerMatch: +cumulativeEmployerMatch.toFixed(0),
          investmentReturn: +cumulativeInvestmentReturns.toFixed(0),
        });
      }
    }

    setMonthlyRecords(records);
    setBarChartData(barRecords);
    setContributions(
      (
        +totalEmployeeContributions +
        +totalEmployerMatch +
        +startingBalance
      ).toFixed(0)
    );
    setEmployeeContributions(
      (+startingBalance + +totalEmployeeContributions).toFixed(0)
    );
    setEmployerMatchResult(+totalEmployerMatch.toFixed(0));
    setInvestmentReturns(+totalInvestmentReturns.toFixed(0));
    setTotalBalance(+balance.toFixed(0));
  }, [
    currentAge,
    annualIncome,
    startingBalance,
    monthlyContribution,
    employerMatch,
    employerMatchLimit,
    retirementAge,
    estimatedReturn,
    salaryIncrease,
  ]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                label="Current age"
                flag="year"
                min={18}
                max={80}
                setState={setCurrentAge}
                value={currentAge}
              />
              <InputComponent
                isInput={true}
                label="Current annual salary"
                flag="dollar"
                min={100}
                max={100000}
                setState={setAnnualIncome}
                value={annualIncome}
              />

              <InputComponent
                isInput={true}
                label="Current 401(k) balance"
                flag="dollar"
                min={0}
                max={1000000000}
                setState={setStartingBalance}
                value={startingBalance}
              />
              <InputComponent
                isInput={true}
                label="Contributions"
                value={monthlyContribution}
                setState={setMonthlyContribution}
                flag="percentage"
                min={0}
                max={23}
              />

              <InputComponent
                isInput={true}
                label="Employer match"
                flag="percentage"
                setState={setEmployerMatch}
                value={employerMatch}
                min={0}
                max={100}
              />

              <InputComponent
                isInput={true}
                label="Employer match limit"
                flag="percentage"
                min={0}
                max={99}
                setState={setEmployerMatchLimit}
                value={employerMatchLimit}
              />

              <InputComponent
                isInput={true}
                label="Retirement age"
                flag="year"
                min={0}
                max={120}
                setState={setRetirementAge}
                value={retirementAge}
              />
              <InputComponent
                isInput={true}
                label="Expected salary increase"
                flag="percentage"
                min={0}
                max={50}
                setState={setSalaryIncrease}
                value={salaryIncrease}
              />
              <InputComponent
                isInput={true}
                label="Expected annual return"
                flag="percentage"
                min={0}
                max={50}
                setState={setEstimatedReturn}
                value={estimatedReturn}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Total contributions"
                isInput={false}
                value={contributions}
                flag="dollar"
              />
              <InputComponent
                label="Employee contributions"
                isInput={false}
                value={employeeContributions}
                flag="dollar"
              />
              <InputComponent
                label={"Employer match"}
                isInput={false}
                value={employerMatchResult}
                flag="dollar"
              />
              <InputComponent
                label="Investment returns"
                isInput={false}
                value={investmentReturns}
                flag="dollar"
              />
              <InputComponent
                label="Total Balance"
                isInput={false}
                value={totalBalance}
                flag="dollar"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} className="piechart-div">
            <PieChartComponent
              data={[
                { id: 0, value: +contributions, label: "Total contributions" },
                {
                  id: 1,
                  value: +investmentReturns,
                  label: "Investment returns",
                },
                {
                  id: 2,
                  value: +totalBalance,
                  label: "Total balance",
                },
              ]}
              flag="invest"
            />
          </Grid>
        </Grid>
        <Grid className="data-table-container">
          <Grid className="table-toggle-div">
            <Typography variant="h5">
              Retirement Schedule{" "}
              <span>
                (<u>Yearly</u>)
              </span>
            </Typography>
            <Tooltip
              title={tableView ? "click to close table" : "click to open table"}
              placement="top"
              arrow
            >
              <span>
                <img
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => setTableView(!tableView)}
                  style={{
                    transform: tableView ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                  className="add-icon-table"
                />
              </span>
            </Tooltip>
          </Grid>
          {tableView && (
            <Grid>
              <Grid>
                <TableComponent
                  rows={monthlyRecords}
                  currency="dollar"
                  headerData={[
                    "Age",
                    "Salary",
                    "Employee Contribution",
                    "Employer Match",
                    "Total Contribution",
                    "Investment Returns",
                    "Balance",
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={5} className="piechart-div">
          <BarChartComponent
            data={[
              {
                data: barChartData?.length
                  ? barChartData?.map(
                      (x) => +startingBalance + x?.employeeContribution
                    )
                  : [0],
                stack: "A",
                label: "Employee contributions",
              },
              {
                data: barChartData?.length
                  ? barChartData?.map((x) => x?.employerMatch)
                  : [0],
                stack: "A",
                label: "Employer match",
              },
              {
                data: barChartData?.length
                  ? barChartData?.map((x) => x?.investmentReturn)
                  : [0],
                stack: "A",
                label: "Investment returns",
              },
            ]}
            xLabels={
              barChartData?.length
                ? barChartData?.map((record) => record?.age)
                : [0]
            }
          />
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default Retirement401KCalc;
