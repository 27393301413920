import { BarChart } from "@mui/x-charts";
import * as React from "react";

const BarChartComponent = (props) => {
  const { data, xLabels } = props;

  return (
    <BarChart
      series={data}
      sx={{
        width: "100%",
        height: { xs: "50% !important", sm: "100% !important" },
        marginTop: "15px",
      }}
      width={600}
      height={350}
      xAxis={xLabels && [{ data: xLabels, scaleType: "band" }]}
    />
  );
};
export default BarChartComponent;
