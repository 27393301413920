import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";
import SelectBoxComponent from "../../Common/SelectBox/SelectBoxComponent";

function ChildTaxCreditCalc() {
  const [filingStatus, setFilingStatus] = useState("Single");
  const [grossIncome, setGrossIncome] = useState(5000);
  const [numOfChild, setNumOfChild] = useState(1);
  const [totalChildCredit, setTotalChildCredit] = useState(0);
  const [refund, setRefund] = useState(0);

  useEffect(() => {
    const creditPerChild = 2000;
    const refundableLimit = 1500;
    const earnedIncomeThreshold = 2500;

    let incomeLimit;
    switch (filingStatus) {
      case "Married Filling Jointly":
        incomeLimit = 400000;
        break;
      default:
        incomeLimit = 200000;
    }

    let totalCredit = 0;
    if (grossIncome <= incomeLimit) {
      totalCredit = numOfChild * creditPerChild;
    } else {
      totalCredit = 0;
    }

    setTotalChildCredit(totalCredit);

    let refundablePortion = 0;
    if (grossIncome > earnedIncomeThreshold) {
      refundablePortion = numOfChild * refundableLimit;
    }
    setRefund(totalCredit === 0 ? 0 : refundablePortion);
  }, [filingStatus, grossIncome, numOfChild]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <SelectBoxComponent
                label={"Filing Status"}
                value={filingStatus}
                setValue={setFilingStatus}
                data={[
                  "Single",
                  "Married Filling Jointly",
                  "Married Filling Separately",
                  "Head of Household",
                  "Qualifying Widow",
                ]}
                placeholder="Select filing status"
              />
              <InputComponent
                isInput={true}
                label="Gross Income ($)"
                flag="dollar"
                min={100}
                max={1000000000}
                setState={setGrossIncome}
                value={grossIncome}
              />
              <InputComponent
                isInput={true}
                label="Number of Qualifying Children (under 17 as of year-end)"
                setState={setNumOfChild}
                value={numOfChild}
                min={0}
                max={10}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Total Child Tax Credit"
                isInput={false}
                value={totalChildCredit}
                flag="dollar"
              />
              <InputComponent
                label="Refundable Portion"
                isInput={false}
                value={refund}
                flag="dollar"
              />
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default ChildTaxCreditCalc;
