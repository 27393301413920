import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";

import TabbingComponent from "../../Common/TabbingRadio/TabbingComponent";

function HeightFromParents() {
  const [units, setUnits] = useState("Metric Units");
  const [motherHeight, setMotherHeight] = useState(164);
  const [fatherHeight, setFatherHeight] = useState(178);

  const [motherHeightInFeet, setMotherHeightInFeet] = useState(5);
  const [motherHeightInInches, setMotherHeightInInches] = useState(2);
  const [fatherHeightInFeet, setFatherHeightInFeet] = useState(5);
  const [fatherHeightInInches, setFatherHeightInInches] = useState(10);

  const [maleAdultHeight, setMaleAdultHeight] = useState(0);
  const [maleAdultHeightInFeet, setMaleAdultHeightInFeet] = useState(0);
  const [maleAdultHeightInInches, setMaleAdultHeightInInches] = useState(0);
  const [femaleAdultHeight, setFemaleAdultHeight] = useState(0);
  const [femaleAdultHeightInFeet, setFemaleAdultHeightInFeet] = useState(0);
  const [femaleAdultHeightInInches, setFemaleAdultHeightInInches] = useState(0);

  const calculateHeights = () => {
    let avgHeightMetric, maleHeightMetric, femaleHeightMetric;

    if (units === "Metric Units") {
      avgHeightMetric = (fatherHeight + motherHeight) / 2;
      maleHeightMetric = Math.round(avgHeightMetric + 6.5);
      femaleHeightMetric = Math.round(avgHeightMetric - 6.5);

      setMaleAdultHeight(maleHeightMetric);
      setFemaleAdultHeight(femaleHeightMetric);
    } else {
      const motherHeightCM =
        motherHeightInFeet * 30.48 + motherHeightInInches * 2.54;
      const fatherHeightCM =
        fatherHeightInFeet * 30.48 + fatherHeightInInches * 2.54;

      avgHeightMetric = (fatherHeightCM + motherHeightCM) / 2;
      maleHeightMetric = avgHeightMetric + 6.5;
      femaleHeightMetric = avgHeightMetric - 6.5;

      const maleHeightInInches = maleHeightMetric / 2.54;
      const femaleHeightInInches = femaleHeightMetric / 2.54;

      const maleHeightFeet = Math.floor(maleHeightInInches / 12);
      const maleHeightInches = (maleHeightInInches % 12).toFixed(1);
      const femaleHeightFeet = Math.floor(femaleHeightInInches / 12);
      const femaleHeightInches = (femaleHeightInInches % 12).toFixed(1);

      setMaleAdultHeightInFeet(maleHeightFeet);
      setMaleAdultHeightInInches(maleHeightInches);
      setFemaleAdultHeightInFeet(femaleHeightFeet);
      setFemaleAdultHeightInInches(femaleHeightInches);
    }
  };

  useEffect(() => {
    calculateHeights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    motherHeight,
    fatherHeight,
    motherHeightInFeet,
    fatherHeightInFeet,
    units,
  ]);

  return (
    <>
      <Grid item xs={12} md={5.9} className="calculation-part ">
        <Typography className="common-sub-heading-calc">
          Predicting Based on the Parents' Heights Only
        </Typography>
        <Grid sx={{ margin: "20px 8px" }}>
          <TabbingComponent
            data={["Metric Units", "US Units"]}
            value={units}
            setValue={setUnits}
          />
        </Grid>
        <Grid>
          {units === "Metric Units" ? (
            <>
              <InputComponent
                isInput={true}
                label="Mother's Height"
                min={0}
                max={250}
                flag="centimeter"
                setState={setMotherHeight}
                value={motherHeight}
              />
              <InputComponent
                isInput={true}
                label="Father's Height"
                min={0}
                max={250}
                flag="centimeter"
                setState={setFatherHeight}
                value={fatherHeight}
              />
            </>
          ) : (
            <>
              <InputComponent
                isInput={true}
                label="Mother's Height (feet)"
                min={0}
                max={8}
                flag="feet"
                setState={setMotherHeightInFeet}
                value={motherHeightInFeet}
              />
              <InputComponent
                isInput={true}
                label="Mother's Height (inches)"
                min={0}
                max={11}
                flag="inches"
                setState={setMotherHeightInInches}
                value={motherHeightInInches}
              />
              <InputComponent
                isInput={true}
                label="Father's Height (feet)"
                min={0}
                max={8}
                flag="feet"
                setState={setFatherHeightInFeet}
                value={fatherHeightInFeet}
              />
              <InputComponent
                isInput={true}
                label="Father's Height (inches)"
                min={0}
                max={11}
                flag="inches"
                setState={setFatherHeightInInches}
                value={fatherHeightInInches}
              />
            </>
          )}
        </Grid>
        <Grid className="result-label">
          <Typography>Result</Typography>
        </Grid>

        <Grid>
          {units === "Metric Units" ? (
            <>
              <InputComponent
                label="Male child's future height"
                isInput={false}
                date={true}
                value={`${maleAdultHeight} cm`}
              />
              <InputComponent
                label="Female child's future height"
                isInput={false}
                date={true}
                value={`${femaleAdultHeight} cm`}
              />
            </>
          ) : (
            <>
              <InputComponent
                label="Male child's future height"
                isInput={false}
                date={true}
                value={`${maleAdultHeightInFeet} feet ${maleAdultHeightInInches} inches`}
              />
              <InputComponent
                label="Female child's future height"
                isInput={false}
                date={true}
                value={`${femaleAdultHeightInFeet} feet ${femaleAdultHeightInInches} inches`}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default HeightFromParents;
