import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../Header/ComponentHeader";
import "../HomeLoanCalc/HomeLoanCalc.scss";
import InputComponent from "../../Common/InputComponent/InputComponent";
import BlogComponent from "../../Common/BlogContainer/BlogComponent";
import { returnHelmetData } from "../../../Utils/CommonFunction";
import SimilarCalc from "../../Common/SimilarCalc/SimilarCalc";

function PercentageIncreaseCalc() {
  const [initialValue, setInitialValue] = useState(50);
  const [finalValue, setFinalValue] = useState(100);
  const [increaseOrDecrease, setIncreaseOrDecrease] = useState(0);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    const difference = +finalValue - +initialValue;
    var percentage = (+difference / +initialValue) * 100;

    setIncreaseOrDecrease(percentage.toFixed(4));
    setDifference(difference);
  }, [initialValue, finalValue]);

  return (
    <>
      {returnHelmetData()}

      <Box sx={{ p: { xs: "8px 16px", md: "10px 24px" } }}>
        <ComponentHeader />
        <Grid container sx={{ p: { xs: "12px 0", md: "12px 30px" } }}>
          <Grid item xs={12} md={7} className="calculation-part">
            <Grid>
              <InputComponent
                isInput={true}
                label="Initial value"
                min={1}
                max={10000000000}
                setState={setInitialValue}
                value={initialValue}
              />
              <InputComponent
                isInput={true}
                label="Final value"
                setState={setFinalValue}
                value={finalValue}
                min={1}
                max={10000000000}
              />
            </Grid>
            <Grid className="result-label">
              <Typography>Result</Typography>
            </Grid>

            <Grid>
              <InputComponent
                label="Increase"
                isInput={false}
                value={increaseOrDecrease}
                flag={"percentage"}
              />
              <InputComponent
                label="Difference"
                isInput={false}
                value={difference}
                date={true}
              />
              <Typography sx={{ ml: 1, mt: 2, fontSize: "1.3rem" }}>
                {finalValue} is a{" "}
                <strong>
                  {Math.abs(increaseOrDecrease)}%{" "}
                  {difference > 0 ? "increase" : "decrease"}
                </strong>{" "}
                from {initialValue}.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <SimilarCalc />
        <BlogComponent />
      </Box>
    </>
  );
}

export default PercentageIncreaseCalc;
